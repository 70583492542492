import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectVoucherState = (state: RootState) => state.voucher;

const selectVoucherDetails = createSelector(
  selectVoucherState,
  (state) => state.voucherDetails
);

const selectVoucherList = createSelector(
  selectVoucherState,
  (state) => state.vouchersList
);

const selectMeta = createSelector(selectVoucherState, (state) => state.meta);

const selectIsListLoading = createSelector(
  selectVoucherState,
  (state) => state.isListLoading
);

const selectFilter = createSelector(
  selectVoucherState,
  (state) => state.filter
);

export const selectors = {
  selectVoucherList,
  selectIsListLoading,
  selectFilter,
  selectMeta,
  selectVoucherDetails
};
