import { SvgIcon } from '@material-ui/core';
import React from 'react';

const DrawerVoucherIcon = () => {
  return (
    <SvgIcon
      viewBox="0 0 24 17"
      style={{ width: '16px', height: '16px', marginRight: '.5em' }}
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.43038 0C1.08812 0 0 1.08248 0 2.41778V5.28608C0 5.95696 0.369616 6.57388 0.962791 6.89304C2.24651 7.58376 2.24651 9.41624 0.962791 10.107C0.369616 10.4261 0 11.043 0 11.7139V14.5822C0 15.9175 1.08812 17 2.43038 17H21.5696C22.9119 17 24 15.9175 24 14.5822V11.7139C24 11.043 23.6304 10.4261 23.0372 10.107C21.7535 9.41624 21.7535 7.58376 23.0372 6.89304C23.6304 6.57388 24 5.95696 24 5.28608V2.41778C24 1.08248 22.9119 0 21.5696 0H2.43038ZM6.73425 6.93331C5.78913 6.01947 6.02799 4.4634 7.20741 3.85042C8.50447 3.17632 10.0195 4.10452 10.0248 5.57634C10.0309 7.2743 7.96721 8.12535 6.73425 6.93331ZM7.24941 11.8901C7.37207 11.6605 15.2204 3.85375 15.4569 3.72598C15.6831 3.60388 16.1645 3.6227 16.4032 3.763C16.8098 4.00191 16.9898 4.60673 16.7781 5.02327C16.6589 5.25787 8.81602 13.0711 8.56827 13.2021C8.29554 13.3462 7.85868 13.3394 7.59916 13.1869C7.17346 12.9368 7.01139 12.3356 7.24941 11.8901ZM15.2387 9.56809C14.4932 9.83246 13.9747 10.5749 13.9775 11.3736C13.9816 12.4886 14.8049 13.308 15.9229 13.3098C16.9954 13.3115 17.8474 12.4571 17.8488 11.3784C17.8507 10.067 16.4911 9.12397 15.2387 9.56809Z"
        fill="#546E7A"
      />
    </SvgIcon>
  );
};

export default DrawerVoucherIcon;
