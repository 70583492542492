import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetVouchersRequest, Voucher, VoucherDataResponse } from './types';
import * as thunks from './thunks';
import { PaginationMeta } from 'src/types';

type State = {
  voucherDetails: VoucherDataResponse;
  vouchersList: Voucher[];
  isListLoading: boolean;
  filter: GetVouchersRequest;
  meta: PaginationMeta;
};

const initialState: State = {
  vouchersList: [],
  isListLoading: false,
  filter: {},
  voucherDetails: {
    success: false,
    data: {
      affiliate: {
        user_id: 0,
        full_name: ''
      },
      id: 0,
      name: '',
      code: '',
      description: '',
      voucher_type: '',
      start_date: '',
      end_date: '',
      amount: '',
      minimum_spend: '',
      cap_amount: '',
      discount_type: '',
      consumed: 0,
      transactions: []
    }
  },
  meta: {}
};

const slice = createSlice({
  name: 'voucher',
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<GetVouchersRequest>) => {
      state.filter = action.payload;
    },
    resetVoucher: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(
      thunks.updateVoucherThunk.fulfilled,
      (state, { payload, meta }) => {
        if (
          payload?.originalData?.data &&
          state.voucherDetails.data.id === meta.arg.id
        ) {
          state.voucherDetails.data = payload.originalData.data;
        }
      }
    );
    builder.addCase(thunks.getVouchersThunk.pending, (state) => {
      state.isListLoading = true;
    });
    builder.addCase(thunks.getVouchersThunk.fulfilled, (state, { payload }) => {
      const { data, meta } = payload.originalData;
      state.vouchersList = data || [];
      state.meta = meta || {};
      state.isListLoading = false;
    });
    builder.addCase(thunks.getVouchersThunk.rejected, (state) => {
      state.isListLoading = false;
    });
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks
};
