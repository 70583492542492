/* eslint-disable no-unused-vars */

import {
  Button,
  Container,
  Grid,
  LinearProgress,
  Typography
} from '@material-ui/core';
import React, { FC, useCallback, useEffect } from 'react';
import { Page } from 'src/components';
import VoucherIcon from 'src/components/icons/VoucherIcon';
import useResolution from 'src/hooks/useResolution';
import { Toolbar } from './components/Toolbar';
import { VoucherTable } from './components/VoucherTable';
import { MobileVoucherList } from './components/MobileVoucherList';
import { useNavigate } from 'react-router';

import { useVouchers } from 'src/hooks/useVouchers';
import { usePermissions } from 'src/hooks';

export const VouchersListingPage: FC = () => {
  const { canCreateVoucher } = usePermissions();
  const navigate = useNavigate();
  const { lg } = useResolution();
  const { getVouchers, isListLoading, } = useVouchers();

  const onClickCreate = useCallback(() => {
    navigate(`/app/vouchers/create`);
  }, [navigate]);

  useEffect(() => {
    getVouchers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page className="root" title="Vouchers">
      <Container style={{ paddingTop: '1em', paddingBottom: '1em' }}>
        <Grid container style={{ rowGap: '1em' }}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h1">Voucher List</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{ display: 'flex', justifyContent: 'end' }}
          >
            {canCreateVoucher ? (
              <Button
                variant="contained"
                color="primary"
                startIcon={<VoucherIcon />}
                onClick={onClickCreate}
              >
                CREATE VOUCHER
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </Container>
      <Toolbar />
      {isListLoading ? <LinearProgress /> : null}
      {lg && !isListLoading ? <MobileVoucherList /> : null}
      {!lg && !isListLoading ? <VoucherTable /> : null}
    </Page>
  );
};
