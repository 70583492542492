import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import React, { FC, useState } from 'react';
import { slices, useAppSelector } from 'src/redux';
import { formatCurrency } from 'src/utils';
import { colors } from 'src/constants';
import { Link } from '@material-ui/core';
import { TransactionWithBal } from 'src/types';
import { AlternatingColorTableRow } from 'src/components/table';
import { useIsMobile } from 'src/hooks/use-is-mobile';
import CloseIcon from '@material-ui/icons/Close';
import { PrintTransactionsWithBalanceModal } from './PrintTransactionsWithBalanceModal';
import PrintIcon from '@material-ui/icons/Print';

interface Props {
  open?: boolean;
  totalBalance?: string;
  handleClose: () => void;
}

const { selectors: transactionsSelectors } = slices.transaction;

export const TransactionsWithBalanceModal: FC<Props> = ({
  open = true,
  totalBalance,
  handleClose
}) => {
  const isMobile = useIsMobile();

  const [isPrintOpen, setIsPrintOpen] = useState<boolean>(false);

  const transactionsWithBal: TransactionWithBal[] =
    useAppSelector(transactionsSelectors.selectTransactionsWithBal) || [];

  return (
    <>
      <PrintTransactionsWithBalanceModal
        isOpen={isPrintOpen}
        handleClose={() => setIsPrintOpen(false)}
      />
      <Modal
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        open={open}
        onClose={handleClose}
      >
        <Box style={{ background: 'white' }}>
          <DialogTitle>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                overflow: 'visible'
              }}
            >
              <Typography variant="h5" style={{ width: '13em' }}>
                Transactions with Balance
              </Typography>
              <CloseIcon color="secondary" onClick={handleClose} />
            </Box>
          </DialogTitle>
          <DialogContent>
            {isMobile ? (
              <Grid
                container
                style={{
                  rowGap: '1em',
                  maxHeight: '70vh',
                  overflowY: 'auto'
                }}
              >
                {transactionsWithBal[0] &&
                  transactionsWithBal.map((i) => (
                    <Grid
                      item
                      container
                      key={i.transaction_no}
                      style={{
                        borderBottom: '1px solid gray',
                        paddingBottom: '1em'
                      }}
                      xs={12}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          columnGap: '1em'
                        }}
                      >
                        <Typography
                          style={{ fontWeight: 'bold', fontSize: '.9em' }}
                        >
                          Transaction No:
                        </Typography>
                        <Link
                          href={`/app/transaction/${i.transaction_no}`}
                          target={'_blank'}
                          rel={'noopener noreferrer'}
                          style={{ fontSize: '.9em' }}
                        >
                          {i.transaction_no}
                        </Link>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          columnGap: '1em'
                        }}
                      >
                        <Typography
                          style={{ fontWeight: 'bold', fontSize: '.9em' }}
                        >
                          Warranty No:
                        </Typography>

                        {i.order_no}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          columnGap: '1em'
                        }}
                      >
                        <Typography
                          style={{ fontWeight: 'bold', fontSize: '.9em' }}
                        >
                          Customer Name:
                        </Typography>

                        {i.customer_name}
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          columnGap: '1em'
                        }}
                      >
                        <Typography
                          style={{ fontWeight: 'bold', fontSize: '.9em' }}
                        >
                          Branch:
                        </Typography>
                        <Typography style={{ fontSize: '.9em' }}>
                          {i.branch.join(', ')}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          columnGap: '1em'
                        }}
                      >
                        <Typography
                          style={{ fontWeight: 'bold', fontSize: '.9em' }}
                        >
                          Amount:
                        </Typography>
                        <Typography
                          style={{ color: colors.blue[600], fontSize: '.9em' }}
                        >
                          {formatCurrency(i.total_amount)}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={5}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          columnGap: '1em'
                        }}
                      >
                        <Typography
                          style={{ fontWeight: 'bold', fontSize: '.9em' }}
                        >
                          Paid:
                        </Typography>
                        <Typography
                          style={{
                            color: colors.purple[600],
                            fontSize: '.9em'
                          }}
                        >
                          {formatCurrency(i.total_payment)}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        style={{
                          marginLeft: '1em',
                          display: 'flex',
                          alignItems: 'center',
                          columnGap: '1em'
                        }}
                      >
                        <Typography
                          style={{ fontWeight: 'bold', fontSize: '.9em' }}
                        >
                          Balance:
                        </Typography>
                        <Typography
                          style={{ color: colors.red[600], fontSize: '.9em' }}
                        >
                          {formatCurrency(i.balance)}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
            ) : (
              <Table
                stickyHeader
                style={{
                  display: 'block',
                  maxHeight: '50vh',
                  overflowY: 'auto'
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ padding: '4px' }}>
                      <Typography style={{ fontWeight: 'bold' }}>
                        Transaction No.
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: 'center',
                        padding: '6px',
                        width: '10em'
                      }}
                    >
                      <Typography style={{ fontWeight: 'bold' }}>
                        Warranty No.
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: 'center',
                        padding: '6px',
                        width: '10em'
                      }}
                    >
                      <Typography style={{ fontWeight: 'bold' }}>
                        Customer Name
                      </Typography>
                    </TableCell>

                    <TableCell style={{ textAlign: 'center', padding: '4px' }}>
                      <Typography style={{ fontWeight: 'bold', width: '10em' }}>
                        Branch
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        width: '10em',
                        textAlign: 'center',
                        padding: '4px'
                      }}
                    >
                      <Typography style={{ fontWeight: 'bold' }}>
                        Total Amount
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: 'center',
                        padding: '6px',
                        width: '10em'
                      }}
                    >
                      <Typography style={{ fontWeight: 'bold' }}>
                        Amount Paid
                      </Typography>
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', padding: '4px' }}>
                      <Typography style={{ fontWeight: 'bold' }}>
                        Balance Remaining
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactionsWithBal[0] &&
                    transactionsWithBal.map((i) => (
                      <AlternatingColorTableRow
                        hover
                        key={i.transaction_no}
                        style={{ height: '5em' }}
                      >
                        <TableCell style={{ padding: '4px' }}>
                          <Link
                            href={`/app/transaction/${i.transaction_no}`}
                            target={'_blank'}
                            rel={'noopener noreferrer'}
                          >
                            {i.transaction_no}
                          </Link>
                        </TableCell>
                        <TableCell style={{ padding: '4px' }}>
                          <Typography style={{ textAlign: 'center' }}>
                            {i?.order_no}
                          </Typography>
                        </TableCell>
                        <TableCell style={{ padding: '4px' }}>
                          {i?.customer_name}
                        </TableCell>
                        <TableCell
                          style={{
                            width: '10em',
                            textAlign: 'center',
                            padding: '6px'
                          }}
                        >
                          <Typography>{i.branch.join(', ')}</Typography>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: 'center', padding: '6px' }}
                        >
                          <Typography style={{ color: colors.blue[600] }}>
                            {formatCurrency(i.total_amount)}
                          </Typography>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: 'center', padding: '6px' }}
                        >
                          <Typography style={{ color: colors.purple[600] }}>
                            {formatCurrency(i.total_payment)}
                          </Typography>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: 'center', padding: '4px' }}
                        >
                          <Typography style={{ color: colors.red[600] }}>
                            {formatCurrency(i.balance)}
                          </Typography>
                        </TableCell>
                      </AlternatingColorTableRow>
                    ))}
                </TableBody>
              </Table>
            )}
            <div
              style={{
                margin: '1em',
                display: 'flex',
                flexDirection: isMobile ? 'column-reverse' : 'row',
                justifyContent: 'space-between',
                rowGap: isMobile ? '1em' : '0em',
                alignItems: isMobile ? 'start' : 'center',
                columnGap: '1em'
              }}
            >
              <Button
                startIcon={<PrintIcon />}
                color="primary"
                variant="contained"
                onClick={() => setIsPrintOpen(true)}
              >
                Print
              </Button>
              <Box display="flex">
                <Typography style={{ fontWeight: 'bold' }}>
                  Total Balance:
                </Typography>
                <Typography color="secondary" style={{ marginLeft: '1em' }}>
                  {totalBalance}
                </Typography>
              </Box>
            </div>
          </DialogContent>
        </Box>
      </Modal>
    </>
  );
};
