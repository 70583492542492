import React, { FC } from 'react';
import { MobileVoucherContainer } from './MobileVoucherContainer';
import { Container } from '@material-ui/core';
import { useVouchers } from 'src/hooks/useVouchers';
import { Pagination } from '@material-ui/lab';

export const MobileVoucherList: FC = () => {
  const { vouchersList, currentPage, onChangePage, lastPage } = useVouchers();

  return (
    <Container>
      <div
        style={{ display: 'flex', justifyContent: 'end', marginBottom: '.5em' }}
      >
        <Pagination
          page={currentPage}
          onChange={onChangePage}
          count={lastPage}
        />
      </div>
      {vouchersList[0]
        ? vouchersList?.map((voucher, index) => (
            <MobileVoucherContainer
              key={index}
              voucher={voucher}
              index={index}
            />
          ))
        : null}
      <div style={{ display: 'flex', justifyContent: 'end', margin: '.5em 0' }}>
        <Pagination
          page={currentPage}
          onChange={onChangePage}
          count={lastPage}
        />
      </div>
    </Container>
  );
};
