import { TableCell, TableRow } from '@material-ui/core';
import React, { FC, useCallback, useMemo } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import { Voucher } from 'src/redux/slices/voucher/types';
import { useNavigate } from 'react-router';
import { usePermissions } from 'src/hooks';
import { useVouchers } from 'src/hooks/useVouchers';
import { DecisionDialog } from 'src/components';

interface Props {
  index: number;
  voucher: Voucher;
}

export const AlternatingVoucherRow: FC<Props> = ({ voucher, index }) => {
  const navigate = useNavigate();
  const { canUpdateVoucher, canDeleteVoucher } = usePermissions();
  const isEven = useMemo(() => (index & 1) === 0, [index]); // fancy checking if number is even
  const { deleteVoucher, onClickDelete, isDelete, setIsDelete } = useVouchers()

  const onClickEdit = useCallback(() => {
    navigate(`/app/vouchers/details/${voucher?.id}`);
  }, [navigate, voucher]);


  return (
    <TableRow style={{ background: !isEven ? 'white' : '#F5F5F5' }}>
      <TableCell>{voucher?.id}</TableCell>
      <TableCell>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {voucher?.code}
        </div>
      </TableCell>
      <TableCell>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {voucher?.discount_type}
        </div>
      </TableCell>
      <TableCell>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {voucher?.affiliate?.full_name || ''}
        </div>
      </TableCell>
      <TableCell>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {voucher?.start_date}
        </div>
      </TableCell>
      <TableCell>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {voucher?.end_date}
        </div>
      </TableCell>
      <TableCell>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {voucher?.consumed}
        </div>
      </TableCell>
      <TableCell>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            color: '#0166AF'
          }}
        >
          {voucher?.amount || '25%'}
        </div>
      </TableCell>
      <TableCell>
        {canDeleteVoucher ? (
          <CloseIcon
            color="secondary"
            style={{ cursor: 'pointer' }}
            onClick={onClickDelete}
          />
        ) : null}
      </TableCell>
      <TableCell>
        {canUpdateVoucher ? (
          <CreateOutlinedIcon
            fontSize="small"
            style={{ cursor: 'pointer', color: '#4A4A4A' }}
            onClick={onClickEdit}
          />
        ) : null}
      </TableCell>
      {isDelete && (
        <DecisionDialog
          isOpen={isDelete}
          title="Delete This Voucher"
          subTitle={`Are you sure you want to delete this voucher? ${voucher?.code || ''}`}
          onHandleConfirmAction={() => deleteVoucher(voucher)}
          onHandleClose={() => setIsDelete(false)}
        />
      )}
    </TableRow>
  );
};
