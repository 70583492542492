import { CommonAxiosResponse } from 'src/types';
import {
  applyVoucherResponse,
  GetVoucherResponse,
  UpdateVoucherResponse,
  VoucherData,
  GetVouchersRequest,
  GetVouchersResponse,
  GetVoucherTypeOptionsResponse,
  VoucherCodeResponse,
  VoucherDataResponse,
  CreateVoucherRequest,
  CreateVoucherResponse
} from './types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from 'src/services/ApiService';
import { RootState } from 'src/redux/store';

export const getVouchersThunk = createAsyncThunk<
  CommonAxiosResponse<GetVouchersResponse>,
  GetVouchersRequest
>('voucher/getVouchersThunk', async (data) => {
  const res = await Api.get(`/voucher/get`, data);
  return res;
});

export const getVouchersForCSVThunk = createAsyncThunk<
  CommonAxiosResponse<GetVouchersResponse>,
  GetVouchersRequest
>('voucher/getVouchersForCSVThunk', async (data) => {
  const res = await Api.get(`/voucher/get`, data);
  return res;
});

export const getVoucherTypeOptionsThunk = createAsyncThunk<
  CommonAxiosResponse<GetVoucherTypeOptionsResponse>
>('voucher/getVoucherTypeOptionsThunk', async () => {
  const res = await Api.get(`/option/get/voucher-discount-type`);
  return res;
});

export const createVoucherThunk = createAsyncThunk<
  CommonAxiosResponse<CreateVoucherResponse>,
  CreateVoucherRequest
>('voucher/createVoucherThunk', async (data) => {
  const response = await Api.post(`/voucher/create`, data);
  return response;
});

export const generateVoucherCodeThunk = createAsyncThunk<
  CommonAxiosResponse<VoucherCodeResponse>
>('voucher/generateVoucherCodeThunk', async () => {
  const response = await Api.get(`/voucher/generate_code`);
  return response;
});

export const getVoucherDataThunk = createAsyncThunk<
  CommonAxiosResponse<VoucherDataResponse>,
  number,
  { state: RootState }
>('voucher/getVoucherDataThunk', async (id) => {
  const response = await Api.get(`/voucher/get/${id}`);
  return response;
});

export const getVoucherThunk = createAsyncThunk<
  CommonAxiosResponse<GetVoucherResponse>,
  number
>('voucher/getVoucherThunk', async (totalAmount) => {
  const response = await Api.get(`/ecomm/voucher/eligible/get`, {
    total_amount: totalAmount
  });
  return response;
});

export const applyVoucherThunk = createAsyncThunk<
  CommonAxiosResponse<applyVoucherResponse>,
  { transaction_no: string; voucher_code: string }
>('voucher/applyVoucherThunk', async ({ transaction_no, voucher_code }) => {
  const res = await Api.post(`/voucher/transaction/apply`, {
    transaction_no: transaction_no,
    voucher_code: voucher_code
  });
  return res;
});

export const deleteVoucherTransaactionThunk = createAsyncThunk<
  CommonAxiosResponse<applyVoucherResponse>,
  { transaction_no: string; voucher_code: string }
>(
  'voucher/deleteVoucherTransaactionThunk',
  async ({ transaction_no, voucher_code }) => {
    const res = await Api.post(`/voucher/transaction/remove`, {
      transaction_no: transaction_no,
      voucher_code: voucher_code
    });
    return res;
  }
);

export const validateVoucherThunk = createAsyncThunk<
  CommonAxiosResponse<applyVoucherResponse>,
  { voucher_code: string; total_amount: number }
>('voucher/validateVoucherThunk', async ({ voucher_code, total_amount }) => {
  const res = await Api.get(`/ecomm/voucher/validate_voucher`, {
    voucher_code: voucher_code,
    total_amount: total_amount
  });
  return res;
});

export const updateVoucherThunk = createAsyncThunk<
  CommonAxiosResponse<UpdateVoucherResponse>,
  VoucherData
>('voucher/updateRmaThunk', async (data) => {
  const response = await Api.patch(`/voucher/update/${data.id}`, data);
  return response;
});

export const deleteVoucherThunk = createAsyncThunk<
  CommonAxiosResponse<UpdateVoucherResponse>,
  number
>('voucher/deleteVoucherThunk', async (id) => {
  const response = await Api.delete(`/voucher/delete/${id}`);
  return response;
});
