/* eslint-disable no-unused-vars */

import React, { FC, useCallback, useMemo } from 'react';
import {
  Grid,
  makeStyles,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import { Voucher } from 'src/redux/slices/voucher/types';
import { useNavigate } from 'react-router';
import { usePermissions } from 'src/hooks';
import { useVouchers } from 'src/hooks/useVouchers';
import { DecisionDialog } from 'src/components';

const useStyles = makeStyles({
  label: {
    padding: '.5em',
    borderRight: '1px solid rgba(0,0,0,0.2)',
    borderLeft: '1px solid rgba(0,0,0,0.2)',
    borderBottom: '1px solid rgba(0,0,0,0.2)',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    fontSize: '.8em'
  },
  lastLabel: {
    padding: '.5em',
    borderRight: '1px solid rgba(0,0,0,0.2)',
    borderLeft: '1px solid rgba(0,0,0,0.2)',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    fontSize: '.8em'
  },
  value: {
    padding: '0 1em',
    borderRight: '1px solid rgba(0,0,0,0.2)',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    fontSize: '.7em'
  },
  lastValue: {
    padding: '0 1em',
    borderRight: '1px solid rgba(0,0,0,0.2)',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    fontSize: '.yem'
  }
});

interface Props {
  index: number;
  voucher: Voucher; // fix in future pr
}

export const MobileVoucherContainer: FC<Props> = ({ voucher, index }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { canUpdateVoucher, canDeleteVoucher } = usePermissions();
  const { deleteVoucher, onClickDelete, isDelete, setIsDelete } = useVouchers();

  const isEven = useMemo(() => (index & 1) === 0, [index]); // fancy checking if number is even

  const onClickEdit = useCallback(() => {
    navigate(`/app/vouchers/details/${voucher?.id}`);
  }, [navigate, voucher]);

  return (
    <Grid container style={{ borderBottom: '1px solid rgba(0,0,0,0.2)' }}>
      <Grid
        item
        xs={12}
        style={{
          border: '1px solid rgba(0,0,0,0.2)',
          display: 'flex',
          justifyContent: 'end',
          padding: '.2em 1em',
          background: !isEven ? 'white' : '#F5F5F5'
        }}
      >
        {canUpdateVoucher ? (
          <CreateOutlinedIcon
            fontSize="small"
            style={{ cursor: 'pointer', color: '#4A4A4A' }}
            onClick={onClickEdit}
          />
        ) : null}

        {canDeleteVoucher ? (
          <CloseIcon
            color="secondary"
            style={{ cursor: 'pointer' }}
            onClick={onClickDelete}
          />
        ) : null}
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={3} className={classes.label}>
          ID
        </Grid>
        <Grid
          item
          xs
          className={classes.value}
          style={{ background: !isEven ? 'white' : '#F5F5F5' }}
        >
          {voucher?.id}
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={3} className={classes.label}>
          CODE
        </Grid>
        <Grid
          item
          xs
          className={classes.value}
          style={{ background: !isEven ? 'white' : '#F5F5F5' }}
        >
          {voucher?.code}
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={3} className={classes.label}>
          TYPE
        </Grid>
        <Grid
          item
          xs
          className={classes.value}
          style={{ background: !isEven ? 'white' : '#F5F5F5' }}
        >
          {voucher?.discount_type}
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={3} className={classes.label}>
          AFFILIATE
        </Grid>
        <Grid
          item
          xs
          className={classes.value}
          style={{ background: !isEven ? 'white' : '#F5F5F5' }}
        >
          {voucher?.affiliate?.full_name || ''}
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={3} className={classes.label}>
          START DATE
        </Grid>
        <Grid
          item
          xs
          className={classes.value}
          style={{ background: !isEven ? 'white' : '#F5F5F5' }}
        >
          {voucher?.start_date}
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={3} className={classes.label}>
          END DATE
        </Grid>
        <Grid
          item
          xs
          className={classes.value}
          style={{ background: !isEven ? 'white' : '#F5F5F5' }}
        >
          {voucher?.end_date}
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={3} className={classes.label}>
          CONSUMED
        </Grid>
        <Grid
          item
          xs
          className={classes.value}
          style={{ background: !isEven ? 'white' : '#F5F5F5' }}
        >
          {voucher?.consumed}
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={3} className={classes.lastLabel}>
          AMOUNT
        </Grid>
        <Grid
          item
          xs
          className={classes.value}
          style={{
            background: !isEven ? 'white' : '#F5F5F5',
            color: '#0166AF'
          }}
        >
          {voucher?.amount || '25%'}
        </Grid>
      </Grid>

      {isDelete && (
        <DecisionDialog
          isOpen={isDelete}
          title="Delete This Voucher"
          subTitle={`Are you sure you want to delete this voucher? ${voucher?.code ||
            ''}`}
          onHandleConfirmAction={() => deleteVoucher(voucher)}
          onHandleClose={() => setIsDelete(false)}
        />
      )}
    </Grid>
  );
};
