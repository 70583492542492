/* eslint-disable no-unused-vars */

import {
  Button,
  Container,
  Grid,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  TextField
} from '@material-ui/core';
import React, { FC, useCallback, useEffect, useState } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { downloadCSV } from 'src/utils';
import { useVouchers } from 'src/hooks/useVouchers';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'src/redux';
import { Voucher } from 'src/redux/slices/voucher/types';

const useStyles = makeStyles({
  customInput: {
    '&.MuiOutlinedInput-root': {
      '&.Mui-focused': {
        backgroundColor: 'transparent' // Remove the gray background on focus
      }
    },
    '& .MuiOutlinedInput-input': {
      backgroundColor: 'transparent !important' // Ensure input itself is transparent
    }
  }
});

export const Toolbar: FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const {
    getVoucherTypeOptions,
    typeOptions,
    vouchersList,
    getVouchers,
    isOptionsLoading,
    keyword,
    onChangeKeyword,
    voucherType,
    isListLoading,
    setVoucherType,
    saveCurrentFilters,
    getVouchersCSV
  } = useVouchers();

  const [isExportLoading, setIsExportLoading] = useState<boolean>(false);

  // any intended
  const renderValue = useCallback((selected: any) => {
    if (!selected) {
      return <span style={{ color: '#aaa' }}>Voucher type</span>;
    }
    return selected;
  }, []);

  const onClickSearch = useCallback(() => {
    saveCurrentFilters();
    getVouchers({
      code: keyword || null,
      page: 1,
      discount_type: voucherType || null
    });
  }, [getVouchers, keyword, saveCurrentFilters, voucherType]);

  const onClickDownload = useCallback(async () => {
    setIsExportLoading(true);
    const csv: Voucher[] = await getVouchersCSV();
    if (!csv[0]) {
      console.error('No data found for exporting');
      setIsExportLoading(false);
      return;
    }

    const formattedCSV = csv.map((i) => ({
      id: i?.id || '',
      code: i?.code || '',
      type: i?.discount_type || '', // Renaming `discount_type` to `type`
      affiliate: i?.affiliate?.full_name || '',
      start_date: i?.start_date || '',
      end_date: i?.end_date || '',
      consumed: i?.consumed || '',
      amount: i?.amount || ''
    }));

    downloadCSV(formattedCSV, 'Vouchers.csv');
    setIsExportLoading(false);
  }, [getVouchersCSV]);

  useEffect(() => {
    getVoucherTypeOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container id="toolbar" style={{ paddingTop: '1em', paddingBottom: '1em' }}>
      <Grid container style={{ columnGap: '1em', rowGap: '1em' }}>
        <Grid item xs={12} sm={5}>
          <TextField
            id="search"
            fullWidth
            required
            variant="outlined"
            size="small"
            onChange={(e) => onChangeKeyword(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && onClickSearch()}
            placeholder="Search code"
            value={keyword}
          />
        </Grid>
        <Grid item xs={12} sm>
          <Select
            disabled={isOptionsLoading || !typeOptions?.[0]}
            fullWidth
            id="voucher-type"
            value={voucherType}
            displayEmpty
            input={<OutlinedInput classes={{ root: classes.customInput }} />}
            style={{ height: '2.5em' }}
            renderValue={renderValue}
          >
            {typeOptions[0]
              ? typeOptions?.map((option, ind) => (
                  <MenuItem
                    key={ind}
                    onClick={() => {
                      setVoucherType((prev) => (prev === option ? '' : option));
                    }}
                  >
                    {option}
                  </MenuItem>
                ))
              : null}
          </Select>
        </Grid>
        <Grid item xs={12} sm>
          <Button
            disabled={isListLoading}
            variant="contained"
            color="primary"
            fullWidth
            onClick={onClickSearch}
            style={{ height: '100%' }}
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            disabled={isExportLoading || isListLoading}
            onClick={onClickDownload}
            variant="outlined"
            startIcon={<GetAppIcon />}
          >
            Export as CSV
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
