import React, { useCallback, useMemo, useState } from 'react';

import {
  Page as PDFPage,
  View,
  Document,
  StyleSheet,
  PDFViewer
} from '@react-pdf/renderer';
import { Container, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Page, SwitchLabel } from 'src/components';
import { useLocation } from 'react-router-dom';
import {
  GetTransactionsViaTransactionNoResponse,
  Transaction,
  TransactionPrintPageData
} from 'src/types';
import {
  formatDate,
  getNumberOrZero,
  initializedWordsOtherThanFirst,
  isEmptyOrSpaces,
  promoPriceToSRP
} from 'src/utils';
import {
  RenderCompanyInfo,
  RenderCustomerInfo,
  RenderDetailEditor,
  RenderEmployeeInfo,
  RenderFooter,
  RenderItemsTable,
  RenderPageNoAndTotal,
  RenderWarrantySlipTitle
} from './components';
import { RenderRMAEditor } from './components/RenderRMAEditor';
import { cloneDeep } from 'lodash';
import { ProductTypeEnum } from 'src/enums';
import { transactionDataCleaner } from './util';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  customerInfoBox: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  printButton: {
    marginBottom: 14
  },
  extraInfo: {
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  switchContainer: {
    margin: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white'
  },
  container: {
    margin: 15,
    flex: 1
  },
  text: {
    fontSize: 8,
    maxLines: 1
  },
  flex: {
    flex: 1
  }
});

// Create Document Component
const MyDocument = () => {
  const classes = useStyles();
  const location: any = useLocation();
  const transactionDetails: GetTransactionsViaTransactionNoResponse | null =
    location.state;

  const [isSIPricing, setIsSIPricing] = useState<boolean>(false);
  const [isBlankPage, setIsBlankPage] = useState<boolean>(false);
  const [
    transactionStateData,
    setTransactionStateData
  ] = useState<GetTransactionsViaTransactionNoResponse | null>(
    transactionDetails
  );
  const [
    prevTransactionState,
    setPrevTransactionState
  ] = useState<GetTransactionsViaTransactionNoResponse | null>();

  const documentTitle = useMemo(() => {
    const firstName = transactionStateData?.customer?.first_name ?? '';
    const lastName = transactionStateData?.customer?.last_name ?? '';
    const transactionNo = transactionStateData?.transaction_no ?? '';
    return `${+new Date()}-${transactionNo}-${firstName}-${lastName}`;
  }, [transactionStateData]);

  const dateSold = useMemo(() => {
    if (
      transactionStateData?.items &&
      transactionStateData?.items?.length > 0
    ) {
      if (transactionStateData?.items[0]?.created_at) {
        return formatDate(transactionStateData?.items[0].created_at);
      }
      return '--';
    }
    return '--';
  }, [transactionStateData]);

  const onUpdateOfTransaction = (
    newData: GetTransactionsViaTransactionNoResponse
  ) => {
    setTransactionStateData(newData);
  };

  const onSalesInvoicePriceToggle = (v: boolean) => {
    setIsSIPricing(v);

    const clonedTransactionState = cloneDeep(transactionStateData);
    // let adjustedSIItems = clonedTransactionState?.items || [];

    if (v) {
      setPrevTransactionState(transactionStateData);
      clonedTransactionState?.items?.map((x: Transaction) => {
        x.amount = promoPriceToSRP(x.amount);
        x.retail_price = promoPriceToSRP(x.retail_price);
      });
      // setTransactionStateData(clonedTransactionState);
      setTransactionStateData((prev) => ({
        ...prev,
        items: prev?.items?.map((x) => {
          const foo = {
            ...x,
            /* Show retail price if amount is unavailable in table cell below for items with quantity by 
            meters eg. rj45 w/ quantity 3 meters because retail price is manually set by operations regardless of meters */
            amount: promoPriceToSRP(x.amount || x.retail_price),
            retail_price: promoPriceToSRP(x.retail_price)
          };
          return foo;
        })
      }));
    } else {
      if (prevTransactionState) {
        setTransactionStateData(prevTransactionState);
      }
    }
  };

  const onUpdateOfRmaItems = (newItems: Transaction[]) => {
    setTransactionStateData((prev) => ({ ...prev, items: newItems }));
  };

  const transactionItems = useMemo(() => {
    let clonedTransactionStateData = cloneDeep(transactionStateData);
    if (clonedTransactionStateData) {
      clonedTransactionStateData.items = clonedTransactionStateData.items?.filter(
        (x) => x?.rma?.is_hidden_print === false || !x?.rma
      );
    }
    const arr: Transaction[] = clonedTransactionStateData?.items || [];
    const reducedItems: Transaction[] = [];
    arr.forEach((item) => {
      const existingIndex = reducedItems?.findIndex(
        (x) =>
          x?.product_name == item?.product_name &&
          // also check price because some products are same but have different retails price
          x?.retail_price === item?.retail_price
      );
      if (existingIndex > -1) {
        reducedItems[existingIndex].quantity =
          (reducedItems[existingIndex].quantity || 0) + (item?.quantity || 0);
        reducedItems[existingIndex].amount =
          (reducedItems[existingIndex].amount || 0) + (item?.amount || 0);

        if (item?.serial_no) {
          reducedItems[existingIndex].serial_nos?.push(item?.serial_no);
        }
      } else {
        if (item.serial_no) {
          item.serial_nos = [item.serial_no];
        }
        reducedItems.push(item);
      }
    });
    return reducedItems;
  }, [transactionStateData]);

  const totalPriceOnPrint = useMemo(() => {
    const totalKachiPoints = Math.abs(transactionStateData?.kachi_points || 0);
    const total = transactionStateData?.items?.reduce((accumulator, item) => {
      let toBeAdded: number = 0;
      if (
        item?.rma?.is_hidden_print ||
        (item?.rma && item?.rma?.is_hidden_print === undefined)
      ) {
        toBeAdded = 0;
      } else {
        toBeAdded = getNumberOrZero(item?.amount);
      }
      return accumulator + toBeAdded;
    }, 0);
    const newTotal = (total || 0) - totalKachiPoints;

    return newTotal;
  }, [transactionStateData]);

  const pagesData = useMemo(() => {
    const limitOfLinePerPage = 22; // hard coded optimal number by manually counting lines before overflow occurs
    let totalLinesAccumulated = 0;

    let pageData: TransactionPrintPageData[] = [
      {
        pageNo: 1,
        page: {
          ...transactionStateData,
          items: []
        }
      }
    ];

    const clonedTransactionItems = cloneDeep(transactionItems);

    clonedTransactionItems.forEach((i, ind) => {
      const name = i.product_name || '';
      const characterPerLine = 43; // no. of characters the productname cell can fit before wrapping to next line
      const filteredSerial =
        i.serial_nos?.filter((serial) => {
          // check serial conditions
          const isValidSerial =
            i?.product_type !== ProductTypeEnum.Consumable &&
            i?.serial_nos &&
            i?.serial_nos?.length > 0;
          // do not allow non serialized items
          const IsSerializeditem =
            !/^SN-\d{13}$/?.test(serial) && !serial?.includes('PCWSN-');

          const serialConditions = [isValidSerial, IsSerializeditem];

          return serialConditions.every((condition) => !!condition);
        }) || [];

      const linesTakenBySerial = filteredSerial.length || 0;
      const pageNoBasedOnLength = pageData?.length;
      const lastIndexOfPageData = pageNoBasedOnLength - 1;
      const isLastPage = ind === clonedTransactionItems.length - 1;
      const nameLength = name.length || 0;
      const linesTakenByName = Math.ceil(nameLength / characterPerLine);
      const linesTakeByWarranty = 1;
      let linesTakenByItem =
        linesTakenByName + linesTakeByWarranty + linesTakenBySerial;

      if (transactionDetails?.kachi_points !== 0 && isLastPage) {
        linesTakenByItem++; // add kachi points to line consideration if its the last item
      }

      const updatedItem = { ...i, serial_nos: filteredSerial };

      if (totalLinesAccumulated + linesTakenByItem <= limitOfLinePerPage) {
        // @ts-ignore: Object is possibly 'null'. Intended.
        pageData[lastIndexOfPageData]?.page.items.push(updatedItem);
        totalLinesAccumulated += linesTakenByItem;
      } else {
        totalLinesAccumulated = 0; // reset total lines val and re add new one
        totalLinesAccumulated += linesTakenByItem;
        pageData.push({
          pageNo: pageNoBasedOnLength + 1,
          page: {
            ...transactionStateData,
            items: [updatedItem]
          }
        });
      }
    });

    const finalData = transactionDataCleaner(pageData);
    return finalData;
  }, [transactionDetails, transactionItems, transactionStateData]);

  const releasedBy = useMemo(() => {
    const elementWithName = transactionStateData?.items?.find(
      (x) => typeof x.released_by_name !== 'undefined'
    );
    // const arrName = elementWithName?.released_by_name;
    let firstAndInitialLastName = initializedWordsOtherThanFirst(
      elementWithName?.released_by_name || ''
    );
    if (isEmptyOrSpaces(firstAndInitialLastName)) {
      firstAndInitialLastName = 'PC Worth';
    }
    return `${elementWithName?.created_by || 0}-${firstAndInitialLastName}`;
  }, [transactionStateData]);

  const RenderPagesBlank = useCallback(() => {
    return (
      <PDFPage size={[421, 612]} style={styles.page}>
        <View style={styles.container}>
          <RenderCompanyInfo
            storeLocation={transactionStateData?.store_address}
          />
          <RenderWarrantySlipTitle orderNo={''} transactionNo={''} />
          <RenderCustomerInfo
            dateSold={''}
            customerInfo={undefined}
            paymentMode={''}
          />
          <RenderItemsTable isBlankPage items={[]} />
          <RenderPageNoAndTotal isBlankPage />
          <RenderEmployeeInfo releasedBy={''} />
          <RenderFooter customerInfo={undefined} />
        </View>
      </PDFPage>
    );
  }, [transactionStateData]);

  const RenderPages = useCallback(() => {
    return pagesData?.map((pageData, i) => (
      <PDFPage key={i} size={[421, 612]} style={styles.page}>
        <View style={styles.container}>
          <RenderCompanyInfo
            storeLocation={transactionStateData?.store_address}
          />
          <RenderWarrantySlipTitle
            orderNo={transactionStateData?.order_no}
            transactionNo={transactionStateData?.transaction_no}
          />
          <RenderCustomerInfo
            dateSold={dateSold}
            customerInfo={transactionStateData?.customer}
            paymentMode={transactionStateData?.payment_mode}
          />
          <RenderItemsTable
            items={pageData?.page?.items}
            kachiPoints={transactionStateData?.kachi_points}
            isLastPage={i === pagesData.length - 1}
          />
          <RenderPageNoAndTotal
            pageNo={pageData?.pageNo}
            pageTotal={pagesData?.length}
            isSIPricing={isSIPricing}
            totalAmount={totalPriceOnPrint}
          />
          <RenderEmployeeInfo releasedBy={releasedBy} />
          <RenderFooter customerInfo={transactionStateData?.customer} />
        </View>
      </PDFPage>
    ));
  }, [
    dateSold,
    pagesData,
    releasedBy,
    isSIPricing,
    totalPriceOnPrint,
    transactionStateData
  ]);

  const hasRMA = useMemo(
    () => transactionStateData?.items?.filter((x) => x.rma)?.length,
    [transactionStateData]
  );

  return (
    <div>
      <RenderDetailEditor
        isSIPricing={isSIPricing}
        transactionDetails={transactionStateData}
        onUpdateOfTransaction={onUpdateOfTransaction}
        onSalesInvoicePriceToggle={onSalesInvoicePriceToggle}
      />
      {hasRMA !== 0 ? (
        <RenderRMAEditor
          onUpdateOfRmaItems={onUpdateOfRmaItems}
          items={transactionStateData?.items || []}
        />
      ) : null}
      <div className={classes.switchContainer}>
        <SwitchLabel
          checked={isBlankPage}
          label="Show Blank Page"
          onChange={(v) => setIsBlankPage(v)}
        />
      </div>
      <Page title="Transaction Details" className={clsx(classes.root)}>
        <Container maxWidth={false}>
          <PDFViewer
            width="100%"
            height="100%"
            style={{ height: '80vh', width: '100%' }}
          >
            <Document title={documentTitle}>
              {isBlankPage ? RenderPagesBlank() : RenderPages()}
            </Document>
          </PDFViewer>
        </Container>
      </Page>
    </div>
  );
};

export default MyDocument;
