/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  InputAdornment,
  Paper,
  Popper,
  SvgIcon,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { CustomInputEvent } from 'src/types';
import {
  findSelectedQuickFilterViaParam,
  getTapTapQuickViewFiltersViaPermission,
  getCustomersQueryBuilder,
  quickFilterBranchesChoices,
  taptapCustomerInitialPayload,
  updateColumnVisibilityUtil
} from 'src/redux/slices/taptap-customer';
import {
  TapTapColumn,
  TapTapQuickFilterView,
  TapTapGetCustomersCondition,
  GetTapTapCustomersPayload,
  TapTapFiltersV2
} from 'src/redux/slices/taptap-customer/types';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { cloneDeep, concat, isEqual } from 'lodash';
import { initialTaptapTableColumns } from 'src/redux/slices/taptap-customer/constants/taptap-table-columns';
import { CheckBoxLabel, DatePickerRangeComponent } from 'src/components';
import {
  convertToLocalISO,
  dateToday,
  getBranchColor,
  downloadSupabaseCSV,
  snakeCaseToTitleCase
} from 'src/utils';
import SearchIcon from '@material-ui/icons/Search';
import { useNavigate } from 'react-router';
import { taptapCustomerField } from 'src/redux/slices/taptap-customer/constants/taptap-fields';
import FilterListIcon from '@material-ui/icons/FilterList';
import { usePermissions, useSnackBar } from 'src/hooks';
import useResolution from 'src/hooks/useResolution';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import GetAppIcon from '@material-ui/icons/GetApp';

interface Props {
  className?: string;
  input?: string;
  isLoading?: boolean;
  hasCustomers?: boolean;
  onSearchCustomer?: (text: string) => void;
  onToolbarInputChange?: (e: CustomInputEvent) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2)
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const {
  actions: taptapCustomerActions,
  selectors: taptapCustomerSelectors
} = slices.taptapCustomer;

const Toolbar = ({
  className,
  hasCustomers = false,
  isLoading = false,
  ...rest
}: Props) => {
  const navigate = useNavigate();
  const snackBar = useSnackBar();

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { xs, xl, width } = useResolution();

  const {
    canDownloadTaptap,
    cannotSelectTaptapBranch,
    userPermissionsStringArray
  } = usePermissions();

  const selectedSearchValue = useAppSelector(
    taptapCustomerSelectors.selectTaptapCustomerSearchValue
  );
  const selectedFilter = useAppSelector(
    taptapCustomerSelectors.selectTaptapCustomerFilter
  );
  const selectedGetPayload = useAppSelector(
    taptapCustomerSelectors.selectTaptapCustomerPayload
  );
  const selectedColumns = useAppSelector(
    taptapCustomerSelectors.selectTaptapCustomerColumns
  );
  const taptapCustomerPayload = useAppSelector(
    taptapCustomerSelectors.selectTaptapCustomerPayload
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorDatePopper, setAnchorDatePopper] = useState(null);
  const [anchorElColumns, setAnchorElColumns] = useState(null);
  const [isExportLoading, setIsExportLoading] = useState(false);

  const [isFilterPopEverOpen, setIsFilterPopEverOpen] = useState<boolean>(
    false
  );
  const [tempPopOverGetPayload, setTempPopOverGetPayload] = useState<
    GetTapTapCustomersPayload
  >(taptapCustomerInitialPayload);

  const [isExportPopEverOpen, setIsExportPopEverOpen] = useState<boolean>(
    false
  );

  const [isColumnsPopOverOpen, setIsColumnsPopOverOpen] = useState<boolean>(
    false
  );
  const [tempPopOverColumns, setTempPopOverColumns] = useState<TapTapColumn[]>(
    []
  );

  const [dateFrom, setDateFrom] = useState<string | null>(null);
  const [dateTo, setDateTo] = useState<string | null>(null);

  const permissionBasedQuickFilterViews = useMemo(
    () => getTapTapQuickViewFiltersViaPermission(userPermissionsStringArray),
    [userPermissionsStringArray]
  );

  const onResetAllTapTapState = () => {
    dispatch(taptapCustomerActions.resetAll());
  };

  const onExportToCSV = useCallback(async () => {
    setIsExportLoading(true);
    const csvPayload = cloneDeep(taptapCustomerPayload);

    csvPayload.pagination.pageSize = 9999;
    csvPayload.pagination.page = 1;

    // do not add date range if
    csvPayload.filters = {
      ...csvPayload.filters,
      conditions: csvPayload.filters.conditions.concat([
        ...(dateFrom
          ? [{ field: 'created_at', operator: 'gte', value: dateFrom }]
          : []),
        ...(dateFrom && dateTo
          ? [{ field: 'created_at', operator: 'lte', value: dateTo }]
          : [])
      ])
    };

    const { data, error } = await getCustomersQueryBuilder({
      payloadArg: csvPayload
    }).csv();

    if (error) {
      snackBar.show({
        useSound: true,
        severity: 'error',
        message: 'Failed at getting taptap csv'
      });
    } else {
      downloadSupabaseCSV(data, 'Taptap_Customers.csv');
    }

    setIsExportLoading(false);
  }, [dateFrom, dateTo, snackBar, taptapCustomerPayload]);

  // eslint-disable-next-line no-unused-vars
  const onDownloadClick = useCallback((e) => {
    setIsExportPopEverOpen(true);
    setAnchorDatePopper(e.currentTarget);
  }, []);

  const onColumnsPopOverOpen = (event: any) => {
    const sortedColumns = cloneDeep(selectedColumns)
      .map((column, i) => ({ ...column, origIndex: i }))
      .sort((a, b) => {
        if (a.field < b.field) {
          return -1;
        }
        if (a.field > b.field) {
          return 1;
        }
        return 0;
      });

    setTempPopOverColumns(sortedColumns); // Set state filter from redux
    setAnchorElColumns(event.currentTarget);
    setIsColumnsPopOverOpen((prev) => !prev);
  };

  const onChangeDate = useCallback(
    (date?: MaterialUiPickersDate, field?: string) => {
      if (!field) return;

      const formattedDate = date
        ? convertToLocalISO(date, field === 'to')
        : null;

      if (field === 'from') setDateFrom(formattedDate);
      if (field === 'to') setDateTo(formattedDate);
    },
    []
  );

  const onColumnsPopOverClose = () => {
    setIsColumnsPopOverOpen(false);
    setTempPopOverColumns([]); // reset state filter to initial
  };

  const onColumnsPopOverReset = () => {
    setTempPopOverColumns(initialTaptapTableColumns); // reset state filter to initial
  };

  const onApplyColumns = () => {
    const clonedColumns = cloneDeep(tempPopOverColumns).sort(
      (a, b) => (a.origIndex || 0) - (b.origIndex || 0)
    );
    dispatch(taptapCustomerActions.updateTaptapColumns(clonedColumns));
    onColumnsPopOverClose();
  };

  const onFilterPopOverOpen = (event: any) => {
    setTempPopOverGetPayload(selectedGetPayload); // Set state filter from redux
    setAnchorEl(event.currentTarget);
    setIsFilterPopEverOpen((prev) => !prev);
  };

  const onFilterPopOverClose = () => {
    setIsFilterPopEverOpen(false);
    setTempPopOverGetPayload(taptapCustomerInitialPayload); // reset state filter to initial
  };

  const onFilterPopOverClear = () => {
    setTempPopOverGetPayload(taptapCustomerInitialPayload); // reset state filter to initial
  };

  const onExportPopOverClear = () => {
    onChangeDate(null, 'from');
    onChangeDate(null, 'to');
  };

  const onApplyFilter = () => {
    const selectedQuickFilter = findSelectedQuickFilterViaParam(
      tempPopOverGetPayload.filters
    );

    // If quick filter is selected. Apply its default column sorting and visible
    if (
      selectedQuickFilter &&
      selectedQuickFilter.defaultColumns &&
      selectedQuickFilter.defaultColumns?.length > 0
    ) {
      const newColumnArrangement = updateColumnVisibilityUtil(
        selectedColumns,
        selectedQuickFilter.defaultColumns
      );

      dispatch(taptapCustomerActions.updateTaptapColumns(newColumnArrangement));
    } else {
      dispatch(taptapCustomerActions.resetTaptapColumns());
    }

    dispatch(
      taptapCustomerActions.updateTaptapCustomerGetPayload(
        tempPopOverGetPayload
      )
    );
    dispatch(taptapCustomerActions.taptapFetchCustomersThunk());
    onFilterPopOverClose();
  };

  const selectedBranchIndicator = useMemo(() => {
    const selectedBranchCopy = selectedFilter?.conditions.find(
      (condition) => condition.field === 'branch_released'
    )?.value;

    return selectedBranchCopy || 'ALL';
  }, [selectedFilter]);

  const selectedFilterIndicator = useMemo(() => {
    const selectedQuickFilter = findSelectedQuickFilterViaParam(selectedFilter);
    return selectedQuickFilter?.label || 'ALL ?';
  }, [selectedFilter]);

  const isBranchSelected = useCallback(
    (params: TapTapGetCustomersCondition) => {
      if (
        tempPopOverGetPayload?.filters?.conditions &&
        tempPopOverGetPayload?.filters?.conditions?.length > 0
      ) {
        const getPayloadFilterConditions =
          tempPopOverGetPayload?.filters?.conditions;
        const fieldInConditions = getPayloadFilterConditions?.find(
          (condition) => condition.field === taptapCustomerField.BRANCH_RELEASED
        );
        if (fieldInConditions) {
          return isEqual(fieldInConditions.value, params.value);
        }
      }

      return false;
    },
    [tempPopOverGetPayload]
  );

  const onQuickFilterSelectBranch = (
    newCondition: TapTapGetCustomersCondition
  ) => {
    const clonedTempGetPayload = cloneDeep(tempPopOverGetPayload);
    const clonedTempPopOverFilter = clonedTempGetPayload?.filters;
    const index = clonedTempPopOverFilter?.conditions?.findIndex(
      (condition) => condition.field === newCondition.field
    );

    if (index !== -1) {
      // If it exists, replace the existing condition or unselect
      if (isEqual(newCondition, clonedTempPopOverFilter.conditions[index])) {
        // Pag selected na yung same quick filter, unselect
        clonedTempPopOverFilter.conditions.splice(index, 1);
      } else {
        clonedTempPopOverFilter.conditions[index] = newCondition;
      }
    } else {
      // If it doesn't exist, push the new condition
      clonedTempPopOverFilter.conditions.push(newCondition);
    }

    setTempPopOverGetPayload(clonedTempGetPayload);
  };

  const isQuickViewFilterSelected = useCallback(
    (quickFilterViewParam: TapTapQuickFilterView) => {
      let clonedTempGetPayload = cloneDeep(tempPopOverGetPayload);
      let clonedTempPopOverFilter = clonedTempGetPayload?.filters;
      clonedTempPopOverFilter.conditions = clonedTempPopOverFilter.conditions.filter(
        (x) => x.field !== taptapCustomerField.BRANCH_RELEASED
      );

      if (isEqual(clonedTempPopOverFilter, quickFilterViewParam.filters)) {
        return true;
      }

      return false;
    },
    [tempPopOverGetPayload]
  );

  const onQuickFilterViewSelect = (quickFilterView: TapTapQuickFilterView) => {
    // Deep clone the tempPopOverFilter to avoid mutation
    let clonedTempGetPayload = cloneDeep(tempPopOverGetPayload);
    let clonedTempPopOverFilter = clonedTempGetPayload?.filters;

    // Find the selected branch condition
    const selectedBranchCondition = clonedTempPopOverFilter?.conditions?.find(
      (condition) => condition?.field === taptapCustomerField.BRANCH_RELEASED
    );
    if (selectedBranchCondition) {
      // Remove branch condition temporarily
      clonedTempPopOverFilter.conditions = clonedTempPopOverFilter.conditions.filter(
        (x) => x.field !== taptapCustomerField.BRANCH_RELEASED
      );
    }
    // Deep clone quickFilterView.filters to avoid mutation
    const clonedQuickFilterView = cloneDeep(quickFilterView);
    let newFilter = clonedQuickFilterView.filters;
    let newSort = clonedQuickFilterView.sort;
    // TODO: SORTING LOGIC ADD HERE
    if (isQuickViewFilterSelected(quickFilterView)) {
      // If the quick filter is already selected, unselect it
      newFilter.conditions = [];
      newSort = taptapCustomerInitialPayload.sort;
    }

    // Add back the branch condition if present
    if (selectedBranchCondition && selectedBranchCondition?.value?.length > 0) {
      newFilter.conditions.push(selectedBranchCondition);
    }
    // Update the state with the new filter
    // Add sorting logic
    setTempPopOverGetPayload({
      ...clonedTempGetPayload,
      filters: newFilter,
      sort: newSort
    });
  };

  const onToolbarInputChange = (e: CustomInputEvent) => {
    dispatch(taptapCustomerActions.updateSearchValue(e.target.value));
  };

  const onSearchClick = () => {
    dispatch(taptapCustomerActions.taptapFetchCustomersThunk());
  };

  const onKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      dispatch(taptapCustomerActions.updateSearchValue(e.target.value));
      dispatch(taptapCustomerActions.taptapFetchCustomersThunk());
    }
  };

  const onAddCustomerClick = () => {
    navigate(`/app/customer-taptap/add`);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <Grid
          container
          style={{
            padding: '1em',
            alignItems: 'center',
            rowGap: '1em',
            columnGap: '1em'
          }}
        >
          <Grid item id="branch-label" xs={12} md={12} xl={12}>
            <div>
              <Typography variant="h4">
                BRANCH:{' '}
                <span
                  style={{
                    color: getBranchColor(
                      Array.isArray(selectedBranchIndicator)
                        ? selectedBranchIndicator[0]
                        : undefined
                    )
                  }}
                >
                  {Array.isArray(selectedBranchIndicator)
                    ? selectedBranchIndicator[0]
                    : ''}
                </span>
                , QUICK VIEW: {selectedFilterIndicator}
              </Typography>
            </div>
          </Grid>
          <Grid
            id="searchbar"
            item
            xs={12}
            md
            lg
            xl
            container
            style={{
              columnGap: '1em',
              rowGap: '1em',
              alignItems: 'center'
            }}
          >
            <Grid item xs={12} sm={2} md lg={2} xl={1}>
              <Typography color="textPrimary" variant="h4">
                Customers
              </Typography>
            </Grid>
            <Grid item xs={9} sm md={9} xl lg>
              <TextField
                disabled={isExportLoading}
                value={selectedSearchValue}
                onChange={onToolbarInputChange}
                fullWidth
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search First Name / Transaction No"
                variant="outlined"
                onKeyPress={onKeyPress}
              />
            </Grid>
            <Grid
              item
              xs={2}
              sm={2}
              md={2}
              xl={2}
              style={{
                height: '2.5em',
                ...(xs && { height: '2.7em' }) // height to match textfield
              }}
            >
              <Button
                disabled={isExportLoading}
                onClick={onSearchClick}
                color="primary"
                variant="contained"
                style={{ width: '100%', height: '100%' }}
              >
                <SearchIcon />
              </Button>
            </Grid>
          </Grid>
          <Grid
            id="toolbar-buttons"
            item
            container
            xs
            md
            xl
            style={{
              alignItems: 'center',
              columnGap: '1em',
              rowGap: '1em',
              ...(xs && {
                height: '6em'
              }),
              ...(xl && {
                justifyContent: 'flex-end'
              })
            }}
          >
            <Grid item xs={5} sm xl={1}>
              <Button
                disabled={isExportLoading}
                onClick={onFilterPopOverOpen}
                color="primary"
                variant="outlined"
                style={{
                  width: '100%'
                }}
              >
                <FilterListIcon />
              </Button>
            </Grid>

            {/* <Button
              onClick={onColumnsPopOverOpen}
              color="primary"
              variant="outlined"
              style={{ marginLeft: 20 }}
            >
              Hide Columns
            </Button> */}

            {canDownloadTaptap ? (
              <Grid item xs sm md xl={2}>
                <Button
                  onClick={onDownloadClick}
                  disabled={!hasCustomers || isLoading || isExportLoading}
                  variant="outlined"
                  color="primary"
                  style={{
                    width: '100%'
                  }}
                >
                  <GetAppIcon />
                </Button>
              </Grid>
            ) : null}

            <Grid item xs={12} sm xl={3}>
              <Button
                disabled={isExportLoading}
                onClick={onAddCustomerClick}
                color="primary"
                variant="contained"
                style={{ width: '100%' }}
              >
                Add customer
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Card>

      <Popper
        open={isExportPopEverOpen}
        anchorEl={anchorDatePopper}
        placement={'bottom'}
        style={{ zIndex: 2 }}
      >
        <Paper elevation={10} style={{ padding: 12 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              marginBottom: '.5em'
            }}
          >
            <Button
              style={{ marginLeft: 10 }}
              size="small"
              variant="text"
              color="secondary"
              onClick={onExportPopOverClear}
            >
              Clear
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              size="small"
              variant="text"
              color="primary"
              onClick={() => setIsExportPopEverOpen(false)}
            >
              Close
            </Button>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={onExportToCSV}
              style={{ marginLeft: 10 }}
            >
              Export to CSV
            </Button>
          </div>
          <Divider />
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            style={{ margin: '1em' }}
          >
            <DatePickerRangeComponent
              title="Created Date Range"
              fromDateMax={dateToday()}
              fromDateValue={dateFrom}
              toDateValue={dateTo}
              dateToLabel="End Date"
              dateFromLabel="Start Date"
              toDateMin={dateFrom}
              onChangeToDate={(date) => onChangeDate(date, 'to')}
              onChangeFromDate={(date) => onChangeDate(date, 'from')}
            />
          </Box>
        </Paper>
      </Popper>

      {/* TODO: Make a component */}
      <Popper
        open={isFilterPopEverOpen}
        anchorEl={anchorEl}
        placement={'bottom'}
        style={{ zIndex: 2 }}
      >
        <Paper elevation={10} style={{ padding: 12 }}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
            mr={1}
          >
            <Typography variant="h4">Quick Filters</Typography>
            <div>
              <Button
                style={{ marginLeft: 10 }}
                size="small"
                variant="text"
                color="secondary"
                onClick={onFilterPopOverClear}
              >
                Clear
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                size="small"
                variant="text"
                color="primary"
                onClick={onFilterPopOverClose}
              >
                Close
              </Button>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={onApplyFilter}
                style={{ marginLeft: 10 }}
              >
                Apply
              </Button>
            </div>
          </Box>
          <Divider />
          {cannotSelectTaptapBranch ? null : (
            <div>
              <Typography style={{ marginTop: 20 }} variant="h5">
                Branch
              </Typography>
              <Box mt={1}>
                {quickFilterBranchesChoices?.map((choice) => {
                  const branchName = Array.isArray(choice.value)
                    ? choice.value[0]
                    : choice.value;
                  const branchColor = getBranchColor(branchName);

                  return (
                    <Button
                      key={choice?.field}
                      color="primary"
                      style={{
                        marginRight: 10,
                        color: branchColor
                      }}
                      onClick={() => onQuickFilterSelectBranch(choice)}
                      variant={
                        isBranchSelected(choice) ? 'contained' : 'outlined'
                      }
                    >
                      {branchName}
                    </Button>
                  );
                })}
              </Box>
            </div>
          )}
          <Typography style={{ marginTop: 20 }} variant="h5">
            Quick Views
          </Typography>
          <Box mt={1}>
            {permissionBasedQuickFilterViews?.map((choice) => (
              <Button
                key={choice.id}
                style={{ marginRight: 10 }}
                color="primary"
                onClick={() => onQuickFilterViewSelect(choice)}
                variant={
                  isQuickViewFilterSelected(choice) ? 'contained' : 'outlined'
                }
              >
                {choice.label}
              </Button>
            ))}
          </Box>
        </Paper>
      </Popper>

      {/* TODO: Make a component */}
      <Popper
        open={isColumnsPopOverOpen}
        anchorEl={anchorElColumns}
        placement={'bottom'}
        style={{ zIndex: 9999, width: 500 }}
      >
        <Paper elevation={10} style={{ padding: 12 }}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
            mr={1}
          >
            <Typography variant="h4">Hide / Unhide Columns</Typography>
            <div>
              <Button
                style={{ marginLeft: 10 }}
                size="small"
                variant="text"
                color="secondary"
                onClick={onColumnsPopOverReset}
              >
                Clear
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                size="small"
                variant="text"
                color="primary"
                onClick={onColumnsPopOverClose}
              >
                Close
              </Button>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={onApplyColumns}
                style={{ marginLeft: 10 }}
              >
                Apply
              </Button>
            </div>
          </Box>
          <Divider />

          <Typography style={{ marginTop: 20 }} variant="h5">
            Quick Views
          </Typography>
          <Box mt={1}>
            {tempPopOverColumns?.map((column) => (
              <CheckBoxLabel
                key={column.field}
                label={snakeCaseToTitleCase(column.field)}
                checked={column.visible}
                onChange={(value) => {
                  setTempPopOverColumns((prev) => {
                    const index = prev.findIndex(
                      (item) => item.field === column.field
                    );

                    const newColumns = [...prev];
                    newColumns[index] = {
                      ...newColumns[index],
                      visible: value
                    };

                    return newColumns;
                  });
                }}
              />
            ))}
          </Box>
        </Paper>
      </Popper>
    </div>
  );
};

export default Toolbar;
