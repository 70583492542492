/* eslint-disable no-unused-vars */

import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import React, { FC, useCallback, useEffect } from 'react';
import { AlternatingVoucherRow } from './AlternatingVoucherRow';
import { useVouchers } from 'src/hooks/useVouchers';
import { Pagination } from '@material-ui/lab';

export const VoucherTable: FC = () => {
  const { vouchersList, currentPage, lastPage, onChangePage } = useVouchers();

  return (
    <div style={{ padding: '1em' }}>
      <Card>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  Code
                </div>
              </TableCell>
              <TableCell>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  Type
                </div>
              </TableCell>
              <TableCell>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  Affiliate
                </div>
              </TableCell>
              <TableCell>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  Start Date
                </div>
              </TableCell>
              <TableCell>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  End Date
                </div>
              </TableCell>
              <TableCell>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  Consumed
                </div>
              </TableCell>
              <TableCell>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  Amount
                </div>
              </TableCell>
              {/* intended empty cells below */}
              <TableCell>{''}</TableCell>
              <TableCell>{''}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vouchersList?.[0]
              ? vouchersList?.map((voucher, ind) => (
                  <AlternatingVoucherRow
                    key={voucher.id}
                    voucher={voucher}
                    index={ind}
                  />
                ))
              : null}
          </TableBody>
        </Table>
      </Card>
      <div style={{ display: 'flex', justifyContent: 'end', margin: '.5em 0' }}>
        <Pagination
          page={currentPage}
          onChange={onChangePage}
          count={lastPage}
        />
      </div>
    </div>
  );
};
