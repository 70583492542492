import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Tooltip,
  TextareaAutosize,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { Page } from 'src/components';
import { DatePickerRangeComponentV2 } from 'src/components';
import { useFormik } from 'formik';
import useResolution from 'src/hooks/useResolution';
import HelpIcon from '@material-ui/icons/Help';
import { useVouchers } from 'src/hooks/useVouchers';
import moment from 'moment';
import { CreateVoucherRequest } from 'src/redux/slices/voucher/types';
import { constants } from 'src/redux/slices/voucher';
import { usePermissions } from 'src/hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    padding: theme.spacing(1)
  },
  container: {
    display: 'flex',
    gap: '10px'
  },
  button: {
    padding: '0px',
    overflow: 'hidden'
  }
}));

const CreateVoucherView = () => {
  const { canCreateVoucher } = usePermissions();
  const classes = useStyles();
  const { lg, sm, xl_plus, xl } = useResolution();
  const { generateVoucherCode, createVoucher, isCreateLoading } = useVouchers();
  const navigate = useNavigate();

  const { purchaseIcon, shippingIcon } = constants;

  // TODO: change get from api in the
  const discountTypeOptions = [
    { label: 'Fix Amount', value: 'amount' },
    { label: 'By Percentage', value: 'percentage' }
  ];

  const initialValues: CreateVoucherRequest = {};

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      createVoucher(values);
    }
  });

  const isPercentage = formik.values.discount_type === 'percentage';

  const onClickGenerate = useCallback(async () => {
    const code = await generateVoucherCode();

    if (code) {
      formik.setFieldValue('code', code);
    }
  }, [formik, generateVoucherCode]);

  return (
    <Page className={classes.root} title="Create Voucher">
      <Box p={1}>
        <form onSubmit={formik.handleSubmit}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Typography variant="h4" gutterBottom>
              Create Voucher
            </Typography>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate('/app/vouchers')}
            >
              Back
            </Button>
          </Box>
          <Box mb={2}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={5}>
                <Box display="flex" flexDirection="column">
                  <Typography>Voucher Name:</Typography>
                  <TextField
                    name="name"
                    value={formik?.values?.name}
                    onChange={formik.handleChange}
                    fullWidth
                    size="small"
                    variant="outlined"
                  />
                </Box>
              </Grid>
              <Grid item container xs={12} md={2}>
                <Grid
                  item
                  container
                  xs={12}
                  xl={6}
                  style={{
                    ...(xl_plus && {
                      justifyContent: 'center',
                      alignItems: 'end',
                      flexDirection: 'column'
                    })
                  }}
                >
                  <Grid
                    item
                    xs={7}
                    style={{
                      ...(xl_plus && {
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'center'
                      })
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        whiteSpace: 'nowrap',
                        marginRight: window.innerWidth >= 960 ? '10px' : '0'
                      }}
                    >
                      Voucher Type:
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} xl={6} style={{ display: 'flex' }}>
                  <Button
                    className={classes.button}
                    color="primary"
                    onClick={() =>
                      formik.setFieldValue('voucher_icon_url', purchaseIcon)
                    }
                    style={{
                      opacity:
                        formik.values.voucher_icon_url === purchaseIcon
                          ? 1
                          : 0.5,
                      filter:
                        formik.values.voucher_icon_url === purchaseIcon
                          ? 'none'
                          : 'grayscale(100%) brightness(0.7)',
                      pointerEvents: 'auto',
                      marginRight: '.2em'
                    }}
                  >
                    <img
                      src="https://devs-api-assets.s3.ap-east-1.amazonaws.com/assets/voucher/type_icon/purchase_discount.png"
                      alt="Purchase"
                      style={{ width: 79.29, height: 66.69 }}
                    />
                  </Button>

                  <Button
                    className={classes.button}
                    onClick={() =>
                      formik.setFieldValue('voucher_icon_url', shippingIcon)
                    }
                    style={{
                      opacity:
                        formik.values.voucher_icon_url === shippingIcon
                          ? 1
                          : 0.5,
                      filter:
                        formik.values.voucher_icon_url === shippingIcon
                          ? 'none'
                          : 'grayscale(100%) brightness(0.5)',
                      pointerEvents: 'auto'
                    }}
                  >
                    <img
                      src="https://devs-api-assets.s3.ap-east-1.amazonaws.com/assets/voucher/type_icon/shipping_discount.png"
                      alt="Shipping"
                      style={{ width: 79.29, height: 66.69 }}
                    />
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} md={5}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: sm ? 'column' : 'row',
                    justifyContent: 'flex-start',
                    alignItems: sm ? 'flex-start' : 'center'
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={{ width: '100%' }}
                  >
                    <Typography>Discount Voucher Code:</Typography>
                    <TextField
                      name="code"
                      value={formik.values.code}
                      onChange={(e) => {
                        const newCode = e.target.value
                          .toUpperCase()
                          .replace(/[^A-Z0-9]/g, '');
                        if (newCode.length <= 7) {
                          formik.setFieldValue('code', newCode);
                        }
                      }}
                      fullWidth
                      size="small"
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Typography
                              variant="body1"
                              style={{ fontWeight: 'bold' }}
                            >
                              PCW
                            </Typography>
                            <Divider
                              orientation="vertical"
                              flexItem
                              style={{ margin: '0 8px' }}
                            />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              columnGap: '1em',
                              marginRight: '-.94em'
                            }}
                          >
                            <Typography variant="body2" color="textSecondary">
                              {(formik.values.code || '').length}/7
                            </Typography>
                            <Button
                              variant="contained"
                              onClick={onClickGenerate}
                              color="primary"
                              style={{ height: '3em' }}
                            >
                              Generate
                            </Button>
                          </div>
                        )
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md
                style={{ ...(xl && !lg && { marginTop: '-1em' }) }}
              >
                <Box display="flex" flexDirection="column">
                  <Typography>Description</Typography>

                  <TextareaAutosize
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    placeholder="Voucher Description"
                    style={{
                      fontFamily: 'roboto',
                      fontSize: '.9em',
                      width: '100%',
                      height: '5em',
                      border: '1px solid #ccc',
                      borderRadius: 4,
                      padding: '10px 12px'
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box mb={2}>
            <Grid
              container
              alignItems="center"
              style={{
                alignItems: 'end',
                columnGap: '1em',
                rowGap: '1em'
              }}
            >
              <Grid item xs={12} sm={isPercentage ? 12 : 4} lg xl>
                <Typography>Discount Type:</Typography>
                <Autocomplete
                  options={discountTypeOptions}
                  getOptionLabel={(option) => option.label}
                  value={
                    discountTypeOptions.find(
                      (opt) => opt.value === formik.values.discount_type
                    ) || null
                  }
                  onChange={(e, val) => {
                    formik.setFieldValue('discount_type', val?.value || '');
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              {formik.values.discount_type === 'percentage' ? (
                <>
                  <Grid item xs={12} lg xl>
                    <Typography>Percentage Off:</Typography>
                    <TextField
                      type="number"
                      value={
                        formik.values.amount === null
                          ? ''
                          : formik.values.amount
                      }
                      name=""
                      fullWidth
                      variant="outlined"
                      onChange={(e) => {
                        let value =
                          e.target.value === '' ? null : +e.target.value;
                        if (value !== null && value > 100) value = 100; // Clamp max to 100
                        formik.setFieldValue('amount', value);
                      }}
                      placeholder="Enter Percentage"
                      size="small"
                      inputProps={{
                        min: 0, // Prevent negative values
                        max: 100, // Set a max limit
                        step: 1 // Ensure whole numbers only (no decimals)
                      }}
                      InputProps={{
                        endAdornment: (
                          <Box
                            style={{
                              display: 'flex',
                              justifyContent: 'end',
                              alignItems: 'center',
                              borderLeft: '1px solid rgba(0,0,0,0.2)',
                              width: '1.5em',
                              height: '2.25em'
                            }}
                          >
                            <Typography style={{ fontSize: '1em' }}>
                              %
                            </Typography>
                          </Box>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={10} sm={11} lg xl>
                    <Typography>Percentage Discount Cap:</Typography>
                    <TextField
                      fullWidth
                      value={formik.values.cap_amount}
                      name="cap_amount"
                      onChange={formik.handleChange}
                      type="number"
                      variant="outlined"
                      placeholder="Enter Discount Cap"
                      size="small"
                    />
                  </Grid>
                  <Grid item style={{ height: '2em' }}>
                    <Tooltip title="The maximum discount amount value allowed for this percentage type voucher">
                      <HelpIcon style={{ color: '#373F50' }} />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} lg xl>
                    <Typography>Min. Spend:</Typography>
                    <TextField
                      name="min_spend"
                      placeholder="Enter Amount"
                      InputProps={{
                        startAdornment: <p style={{ marginRight: '1em' }}>₱</p>
                      }}
                      value={formik.values.min_spend}
                      onChange={(e) =>
                        formik.setFieldValue(e.target.value, 'min_spend')
                      }
                      fullWidth
                      size="small"
                      type="number"
                      variant="outlined"
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} sm lg container>
                  <Grid item xs={6} sm style={{ paddingRight: '1em' }}>
                    <Typography>Amount Off:</Typography>
                    <TextField
                      name="amount"
                      value={formik.values.amount}
                      onChange={formik.handleChange}
                      fullWidth
                      size="small"
                      type="number"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs sm lg={isPercentage ? 4 : 6}>
                    <Typography>Min. Spend:</Typography>
                    <TextField
                      name="min_spend"
                      value={formik.values.min_spend}
                      onChange={formik.handleChange}
                      fullWidth
                      size="small"
                      type="number"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12} sm={isPercentage ? 12 : 2} lg>
                <Typography>Affiliate ID.</Typography>
                <TextField
                  type="number"
                  name="affiliate_user_id"
                  fullWidth
                  variant="outlined"
                  placeholder="Enter PCW User ID"
                  size="small"
                  value={formik.values.affiliate_user_id}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    formik.setFieldValue(name, value === '0' ? null : value);
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box
            mb={2}
            justifyItems={'center'}
            style={{
              ...(!lg && {
                padding: '0 20em'
              })
            }}
          >
            <DatePickerRangeComponentV2
              fromDateValue={formik.values.start_date}
              toDateValue={formik.values.end_date}
              onChangeToDate={(date) => {
                const formattedDate = moment(date, [
                  'MMMM D, YYYY hh:mm:ss A',
                  'YYYY-MM-DD HH:mm:ss'
                ]).format('YYYY-MM-DD HH:mm:ss');
                formik.setFieldValue('end_date', formattedDate);
              }}
              onChangeFromDate={(date) => {
                const formattedDate = moment(date, [
                  'MMMM D, YYYY hh:mm:ss A',
                  'YYYY-MM-DD HH:mm:ss'
                ]).format('YYYY-MM-DD HH:mm:ss');
                formik.setFieldValue('start_date', formattedDate);
                formik.setFieldValue('end_date', null);
              }}
            />
          </Box>

          <Box mt={2} textAlign="center">
            {canCreateVoucher ? (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={isCreateLoading}
              >
                Publish Voucher
              </Button>
            ) : null}
          </Box>
        </form>
      </Box>
    </Page>
  );
};

export default CreateVoucherView;
