import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Tooltip,
  TextareaAutosize,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { unwrapResult } from '@reduxjs/toolkit';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Page } from 'src/components';
import { DatePickerRangeComponentV2 } from 'src/components';
import { slices, useAppDispatch } from 'src/redux';
import { getVoucherDataThunk } from 'src/redux/slices/voucher/thunks';
import { FormValues } from 'src/redux/slices/voucher/types';
import { useFormik } from 'formik';
import { usePermissions, useSnackBar } from 'src/hooks';
import useResolution from 'src/hooks/useResolution';
import { pickBy, isEqual, isEmpty } from 'lodash';
import moment from 'moment';
import { useVouchers } from 'src/hooks/useVouchers';
import HelpIcon from '@material-ui/icons/Help';
import { constants } from 'src/redux/slices/voucher';

const { actions: voucherActions } = slices.voucher;

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    padding: theme.spacing(1)
  },
  container: {
    display: 'flex',
    gap: '10px'
  },
  button: {
    width: 70,
    height: 70,
    overflow: 'hidden'
  },
  image: {
    width: 70,
    height: 70
  }
}));

const VoucherDetails = () => {
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const snackbar = useSnackBar();
  const { lg, sm, xl_plus, xl } = useResolution();
  const navigate = useNavigate();
  const { canUpdateVoucher } = usePermissions();

  const { generateVoucherCode } = useVouchers();
  const { purchaseIcon, shippingIcon } = constants;

  const [isLoading, setIsLoading] = useState(false);

  const discountTypeOptions = [
    { label: 'Fix Amount', value: 'amount' },
    { label: 'By Percentage', value: 'percentage' }
  ];

  const [userFullName, setUserFullName] = useState('');
  // const [discountType, setDiscountType] = useState('');

  const handleVoucherUpdate = async (newValues: any) => {
    setIsLoading(true);

    if (!id) {
      setIsLoading(false);
      console.error('Voucher ID is missing');
      snackbar.show({
        severity: 'error',
        message: 'Voucher ID is missing'
      });
      return;
    }

    const changedValues = pickBy(newValues, (value, key) => {
      const initialValues = formik?.initialValues ?? {}; // Ensure it's always an object
      return !isEqual(value, initialValues[key]);
    });

    if (isEmpty(changedValues)) {
      setIsLoading(false);
      snackbar.show({
        severity: 'error',
        message: JSON.stringify('No changes detected')
      });
      return;
    }

    try {
      const updateVoucherRes = unwrapResult(
        await dispatch(
          voucherActions.updateVoucherThunk({
            id: Number(id),
            ...changedValues
          })
        )
      );

      if (!updateVoucherRes?.success) {
        console.error(
          'errData',
          updateVoucherRes?.message || 'Error Updating Data'
        );
        snackbar.show({
          severity: 'error',
          message: JSON.stringify(
            updateVoucherRes?.message || 'Error Updating Data'
          )
        });

        setIsLoading(false);
        return;
      }

      if (updateVoucherRes?.success) {
        if (updateVoucherRes?.originalData?.data) {
          snackbar.show({
            severity: 'success',
            message: 'Voucher updated successfully'
          });
        }
        setIsLoading(false);
        getVoucherDetails();
      }
    } catch (error) {
      console.error('Failed to update voucher:', error);
      snackbar.show({
        severity: 'error',
        message: 'Failed to update voucher'
      });
      setIsLoading(false);
    }
  };

  const initialValues: FormValues = {};

  const formik = useFormik({
    initialValues,
    onSubmit: handleVoucherUpdate
  });

  const isPercentage = formik.values.discount_type === 'percentage';

  const onClickGenerate = useCallback(async () => {
    const code = await generateVoucherCode();

    if (code) {
      formik.setFieldValue('code', code);
    }
  }, [formik, generateVoucherCode]);

  const getVoucherDetails = useCallback(async () => {
    if (!id) return;

    setIsLoading(true);

    try {
      const getVoucherRes = unwrapResult(
        await dispatch(getVoucherDataThunk(Number(id)))
      );

      if (!getVoucherRes?.success) {
        snackbar.show({
          severity: 'error',
          message: getVoucherRes?.message || 'Error Getting Data'
        });
        console.error(
          'Error fetching voucher data:',
          getVoucherRes?.message || 'Unknown error'
        );
        return;
      }

      if (getVoucherRes?.originalData) {
        const voucherData = getVoucherRes.originalData;
        const formattedIconUrl = voucherData?.data?.voucher_icon_url?.replace(
          /^https?:\/\/[^/]+\/+/,
          '/'
        ); // Remove domain

        setUserFullName(voucherData.data.affiliate?.full_name || '');

        // Define the initial form values
        const initialValues = {
          affiliate_user_id: voucherData?.data?.affiliate?.user_id ?? '',
          name: voucherData?.data?.name ?? '',
          code: (voucherData?.data?.code ?? '').replace(/^PCW/, ''),
          voucher_icon_url: formattedIconUrl ?? '',
          description: voucherData?.data?.description ?? '',
          start_date: voucherData?.data?.start_date ?? null,
          end_date: voucherData?.data?.end_date ?? null,
          discount_type: voucherData?.data?.discount_type ?? '',
          amount: voucherData?.data?.amount ?? 0,
          min_spend: voucherData?.data?.minimum_spend ?? 0,
          cap_amount: voucherData?.data?.cap_amount ?? 0
        };

        formik.resetForm({ values: initialValues });
      }
    } catch (error) {
      snackbar.show({
        severity: 'error',
        message: 'Failed to fetch voucher details'
      });
      console.error('Failed to fetch voucher details:', error);
    } finally {
      setIsLoading(false);
    }
  }, [id, dispatch, snackbar, formik]);

  useEffect(() => {
    getVoucherDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Page className={classes.root} title="Voucher Details">
      <Box p={1}>
        <form onSubmit={formik.handleSubmit}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Typography variant="h4" gutterBottom>
              Voucher Details - {userFullName}
            </Typography>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate('/app/vouchers')}
            >
              Back
            </Button>
          </Box>
          <Box mb={2}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={5}>
                <Box display="flex" flexDirection="column">
                  <Typography>Voucher Name:</Typography>
                  <TextField
                    name="name"
                    value={formik?.values?.name}
                    onChange={formik.handleChange}
                    fullWidth
                    size="small"
                    variant="outlined"
                  />
                </Box>
              </Grid>
              <Grid item container xs={12} md={2}>
                <Grid
                  item
                  container
                  xs={12}
                  xl={6}
                  style={{
                    ...(xl_plus && {
                      justifyContent: 'center',
                      alignItems: 'end',
                      flexDirection: 'column'
                    })
                  }}
                >
                  <Grid
                    item
                    xs={7}
                    style={{
                      ...(xl_plus && {
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'center'
                      })
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        whiteSpace: 'nowrap',
                        marginRight: window.innerWidth >= 960 ? '10px' : '0'
                      }}
                    >
                      Voucher Type:
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12} xl={6} style={{ display: 'flex' }}>
                  <Button
                    className={classes.button}
                    color="primary"
                    onClick={() =>
                      formik.setFieldValue('voucher_icon_url', purchaseIcon)
                    }
                    style={{
                      opacity:
                        formik.values.voucher_icon_url === purchaseIcon
                          ? 1
                          : 0.5,
                      filter:
                        formik.values.voucher_icon_url === purchaseIcon
                          ? 'none'
                          : 'grayscale(100%) brightness(0.7)',
                      pointerEvents: 'auto',
                      marginRight: '.2em'
                    }}
                  >
                    <img
                      src="https://devs-api-assets.s3.ap-east-1.amazonaws.com/assets/voucher/type_icon/purchase_discount.png"
                      alt="Purchase"
                      style={{
                        width: 79.29,
                        height: 66.69
                      }}
                    />
                  </Button>

                  <Button
                    className={classes.button}
                    onClick={() =>
                      formik.setFieldValue('voucher_icon_url', shippingIcon)
                    }
                    style={{
                      opacity:
                        formik.values.voucher_icon_url === shippingIcon
                          ? 1
                          : 0.5,
                      filter:
                        formik.values.voucher_icon_url === shippingIcon
                          ? 'none'
                          : 'grayscale(100%) brightness(0.5)',
                      pointerEvents: 'auto'
                    }}
                  >
                    <img
                      src="https://devs-api-assets.s3.ap-east-1.amazonaws.com/assets/voucher/type_icon/shipping_discount.png"
                      alt="Shipping"
                      style={{ width: 79.29, height: 66.69 }}
                    />
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} md={5}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: sm ? 'column' : 'row',
                    justifyContent: 'flex-start',
                    alignItems: sm ? 'flex-start' : 'center'
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={{ width: '100%' }}
                  >
                    <Typography>Discount Voucher Code:</Typography>
                    <TextField
                      name="code"
                      value={formik.values.code}
                      disabled={true}
                      onChange={(e) => {
                        const newCode = e.target.value
                          .toUpperCase()
                          .replace(/[^A-Z0-9]/g, '');
                        if (newCode.length <= 7) {
                          formik.setFieldValue('code', newCode);
                        }
                      }}
                      fullWidth
                      size="small"
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Typography
                              variant="body1"
                              style={{ fontWeight: 'bold' }}
                            >
                              PCW
                            </Typography>
                            <Divider
                              orientation="vertical"
                              flexItem
                              style={{ margin: '0 8px' }}
                            />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              columnGap: '1em',
                              marginRight: '-.94em'
                            }}
                          >
                            <Typography variant="body2" color="textSecondary">
                              {(formik.values.code || '').length}/7
                            </Typography>
                            <Button
                              disabled={true}
                              variant="contained"
                              onClick={onClickGenerate}
                              color="primary"
                              style={{ height: '3em' }}
                            >
                              Generate
                            </Button>
                          </div>
                        )
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md
                style={{ ...(xl && !lg && { marginTop: '-1em' }) }}
              >
                <Box display="flex" flexDirection="column">
                  <Typography>Description</Typography>

                  <TextareaAutosize
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    placeholder="Voucher Description"
                    style={{
                      fontFamily: 'roboto',
                      fontSize: '.9em',
                      width: '100%',
                      height: '5em',
                      border: '1px solid #ccc',
                      borderRadius: 4,
                      padding: '10px 12px'
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box mb={2}>
            <Grid
              container
              alignItems="center"
              style={{
                alignItems: 'end',
                columnGap: '1em',
                rowGap: '1em'
              }}
            >
              <Grid item xs={12} sm={isPercentage ? 12 : 4} lg xl>
                <Typography>Discount Type:</Typography>
                <Autocomplete
                  options={discountTypeOptions}
                  getOptionLabel={(option) => option.label}
                  value={
                    discountTypeOptions.find(
                      (opt) => opt.value === formik.values.discount_type
                    ) || null
                  }
                  onChange={(e, val) => {
                    formik.setFieldValue('discount_type', val?.value || '');
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              {formik.values.discount_type === 'percentage' ? (
                <>
                  <Grid item xs={12} lg xl>
                    <Typography>Percentage Off:</Typography>
                    <TextField
                      type="number"
                      value={
                        formik.values.amount === null
                          ? ''
                          : formik.values.amount
                      }
                      name=""
                      fullWidth
                      variant="outlined"
                      onChange={(e) => {
                        let value =
                          e.target.value === '' ? null : +e.target.value;
                        if (value !== null && value > 100) value = 100; // Clamp max to 100
                        formik.setFieldValue('amount', value);
                      }}
                      placeholder="Enter Percentage"
                      size="small"
                      inputProps={{
                        min: 0, // Prevent negative values
                        max: 100, // Set a max limit
                        step: 1 // Ensure whole numbers only (no decimals)
                      }}
                      InputProps={{
                        endAdornment: (
                          <Box
                            style={{
                              display: 'flex',
                              justifyContent: 'end',
                              alignItems: 'center',
                              borderLeft: '1px solid rgba(0,0,0,0.2)',
                              width: '1.5em',
                              height: '2.25em'
                            }}
                          >
                            <Typography style={{ fontSize: '1em' }}>
                              %
                            </Typography>
                          </Box>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={10} sm={11} lg xl>
                    <Typography>Percentage Discount Cap:</Typography>
                    <TextField
                      fullWidth
                      value={formik.values.cap_amount}
                      name="cap_amount"
                      onChange={formik.handleChange}
                      type="number"
                      variant="outlined"
                      placeholder="Enter Discount Cap"
                      size="small"
                    />
                  </Grid>
                  <Grid item style={{ height: '2em' }}>
                    <Tooltip title="The maximum discount amount value allowed for this percentage type voucher">
                      <HelpIcon style={{ color: '#373F50' }} />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} lg xl>
                    <Typography>Min. Spend:</Typography>
                    <TextField
                      name="min_spend"
                      placeholder="Enter Amount"
                      InputProps={{
                        startAdornment: <p style={{ marginRight: '1em' }}>₱</p>
                      }}
                      value={formik.values.min_spend}
                      onChange={formik.handleChange}
                      fullWidth
                      size="small"
                      type="number"
                      variant="outlined"
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} sm lg container>
                  <Grid item xs={6} sm style={{ paddingRight: '1em' }}>
                    <Typography>Amount Off:</Typography>
                    <TextField
                      name="amount"
                      value={formik.values.amount}
                      onChange={formik.handleChange}
                      fullWidth
                      size="small"
                      type="number"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs sm lg={isPercentage ? 4 : 6}>
                    <Typography>Min. Spend:</Typography>
                    <TextField
                      name="min_spend"
                      value={formik.values.min_spend}
                      onChange={formik.handleChange}
                      fullWidth
                      size="small"
                      type="number"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12} sm={isPercentage ? 12 : 2} lg>
                <Typography>Affiliate ID.</Typography>
                <TextField
                  type="number"
                  name="affiliate_user_id"
                  fullWidth
                  variant="outlined"
                  placeholder="Enter PCW User ID"
                  size="small"
                  value={formik.values.affiliate_user_id}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    formik.setFieldValue(name, value === '0' ? null : value);
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box
            mb={2}
            justifyItems={'center'}
            style={{
              ...(!lg && {
                padding: '0 20em'
              })
            }}
          >
            <DatePickerRangeComponentV2
              fromDateValue={formik.values.start_date}
              toDateValue={formik.values.end_date}
              onChangeToDate={(date) => {
                const formattedDate = moment(date, [
                  'MMMM D, YYYY hh:mm:ss A',
                  'YYYY-MM-DD HH:mm:ss'
                ]).format('YYYY-MM-DD HH:mm:ss');
                formik.setFieldValue('end_date', formattedDate);
              }}
              onChangeFromDate={(date) => {
                const formattedDate = moment(date, [
                  'MMMM D, YYYY hh:mm:ss A',
                  'YYYY-MM-DD HH:mm:ss'
                ]).format('YYYY-MM-DD HH:mm:ss');
                formik.setFieldValue('start_date', formattedDate);
                formik.setFieldValue('end_date', null);
              }}
            />
          </Box>

          <Box mt={2} textAlign="center">
            {canUpdateVoucher ? (
              <Button
                disabled={isLoading}
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Save Changes
              </Button>
            ) : null}
          </Box>
        </form>
      </Box>
    </Page>
  );
};

export default VoucherDetails;
