import React, { useRef } from 'react';
import { Box, Grid, TextField, Typography } from '@material-ui/core';
import useResolution from 'src/hooks/useResolution';
import moment from 'moment';

interface Props {
  fromDateValue?: string;
  toDateValue?: string;
  onChangeFromDate: (date: string) => void;
  onChangeToDate: (date: string) => void;
}

const normalizeDateFormat = (dateStr: string | null) => {
  if (!dateStr) return null;

  const formattedDate = moment(dateStr, [
    'YYYY-MM-DD HH:mm:ss',
    'MMMM D, YYYY hh:mm:ss A'
  ]).format('YYYY-MM-DD HH:mm:ss');

  return formattedDate === 'Invalid date' ? null : formattedDate;
};

export const DatePickerRangeComponentV2 = ({
  fromDateValue,
  toDateValue,
  onChangeFromDate,
  onChangeToDate
}: Props) => {
  const { xs, md } = useResolution();

  const fromDateRef = useRef<HTMLInputElement | null>(null);
  const toDateRef = useRef<HTMLInputElement | null>(null);

  return (
    <>
      <Grid container>
        <Grid item xs={6} style={{ paddingRight: '1em' }}>
          <Box display="flex" flexDirection="column">
            <Typography variant="h5">Start Date</Typography>
            <TextField
              type="datetime-local"
              variant="outlined"
              size="small"
              value={normalizeDateFormat(fromDateValue ?? null) || ''}
              inputRef={fromDateRef}
              inputProps={{
                min: new Date().toISOString().slice(0, 16),
                style: {
                  ...(xs && { fontSize: '.8em' }),
                  ...(md && { fontSize: '1em' })
                }
              }}
              onChange={(e) => onChangeFromDate(e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" flexDirection="column">
            <Typography variant="h5">End Date</Typography>
            <TextField
              type="datetime-local"
              variant="outlined"
              size="small"
              value={normalizeDateFormat(toDateValue ?? null) || ''}
              inputRef={toDateRef}
              inputProps={{
                min: fromDateValue,
                style: {
                  ...(xs && { fontSize: '.8em' }),
                  ...(md && { fontSize: '1em' })
                }
              }}
              onChange={(e) => onChangeToDate(e.target.value)}
              disabled={!fromDateValue}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
