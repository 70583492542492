import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React, { FC } from 'react';

interface Props {
  fillSvg?: string;
  fillPath?: string;
  svgProps?: SvgIconProps;
  viewBox?: string;
}

const VoucherIcon: FC<Props> = ({
  fillSvg = 'none',
  fillPath = 'white',
  svgProps,
  viewBox = '0 0 24 17'
}) => {
  return (
    <SvgIcon
      {...svgProps}
      viewBox={viewBox}
      width="24"
      height="17"
      fill={fillSvg}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.59494 2.41778C1.59494 1.95877 1.96898 1.58667 2.43038 1.58667H21.5696C22.031 1.58667 22.4051 1.95877 22.4051 2.41778V5.28608C22.4051 5.37432 22.3564 5.45545 22.2784 5.49743C19.8798 6.78803 19.8798 10.212 22.2784 11.5026C22.3564 11.5445 22.4051 11.6257 22.4051 11.7139V14.5822C22.4051 15.0412 22.031 15.4133 21.5696 15.4133H2.43038C1.96898 15.4133 1.59494 15.0412 1.59494 14.5822V11.7139C1.59494 11.6257 1.64355 11.5445 1.72156 11.5026C4.12017 10.212 4.12017 6.78803 1.72156 5.49743C1.64355 5.45545 1.59494 5.37432 1.59494 5.28608V2.41778ZM2.43038 0C1.08812 0 0 1.08248 0 2.41778V5.28608C0 5.95696 0.369616 6.57388 0.962791 6.89304C2.24651 7.58376 2.24651 9.41624 0.962791 10.107C0.369616 10.4261 0 11.043 0 11.7139V14.5822C0 15.9175 1.08812 17 2.43038 17H21.5696C22.9119 17 24 15.9175 24 14.5822V11.7139C24 11.043 23.6304 10.4261 23.0372 10.107C21.7535 9.41624 21.7535 7.58376 23.0372 6.89304C23.6304 6.57388 24 5.95696 24 5.28608V2.41778C24 1.08248 22.9119 0 21.5696 0H2.43038ZM6.73425 6.93331C5.78913 6.01947 6.02799 4.4634 7.20741 3.85042C8.50447 3.17632 10.0195 4.10452 10.0248 5.57634C10.0309 7.2743 7.96721 8.12535 6.73425 6.93331ZM7.24941 11.8901C7.37207 11.6605 15.2204 3.85375 15.4569 3.72598C15.6831 3.60388 16.1645 3.6227 16.4032 3.763C16.8098 4.00191 16.9898 4.60673 16.7781 5.02327C16.6589 5.25787 8.81602 13.0711 8.56827 13.2021C8.29554 13.3462 7.85868 13.3394 7.59916 13.1869C7.17346 12.9368 7.01138 12.3356 7.24941 11.8901ZM15.2387 9.56809C14.4932 9.83246 13.9747 10.5749 13.9775 11.3736C13.9816 12.4886 14.8049 13.308 15.9229 13.3098C16.9954 13.3115 17.8474 12.4571 17.8488 11.3784C17.8507 10.067 16.4911 9.12397 15.2387 9.56809Z"
        fill={fillPath}
      />
    </SvgIcon>
  );
};

export default VoucherIcon;
