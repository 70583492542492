import { unwrapResult } from '@reduxjs/toolkit';
import { useCallback, useMemo, useState } from 'react';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { useSnackBar } from './use-snackbar';
import { CreateVoucherRequest, Voucher } from 'src/redux/slices/voucher/types';

const { actions: voucherActions, selectors: voucherSelectors } = slices.voucher;

export const useVouchers = () => {
  const dispatch = useAppDispatch();
  const snackbar = useSnackBar();

  const vouchersList = useAppSelector(voucherSelectors?.selectVoucherList);
  const meta = useAppSelector(voucherSelectors?.selectMeta);
  const isListLoading = useAppSelector(voucherSelectors?.selectIsListLoading);
  const appliedFilter = useAppSelector(voucherSelectors?.selectFilter);

  const lastPage = useMemo(() => meta?.last_page, [meta]);
  const currentPage = useMemo(() => meta?.current_page, [meta]);

  const [isOptionsLoading, setIsOptionsLoading] = useState<boolean>(false);
  const [isCodeLoading, setIsCodeLoading] = useState<boolean>(false);
  const [typeOptions, setTypeOptions] = useState<string[]>([]);
  const [voucherType, setVoucherType] = useState<string>('');
  const [keyword, setKeyword] = useState<string>('');
  const [isCreateLoading, setIsCreateLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const onChangeKeyword = useCallback((value: string) => {
    setKeyword(value);
  }, []);

  const createVoucher = useCallback(
    async (payload: CreateVoucherRequest) => {
      setIsCreateLoading(true);
      const res = unwrapResult(
        await dispatch(voucherActions.createVoucherThunk(payload))
      );
      if (res?.success) {
        snackbar.show({
          severity: 'success',
          message: 'Voucher created successfully',
          useSound: true
        });
      } else {
        snackbar.show({
          severity: 'error',
          message:
            Object.values(res?.originalData?.errors || {})
              .flat()
              .join(', ') ||
            'Failed to create voucher. Try again later or contact devs',
          useSound: true
        });
      }
      setIsCreateLoading(false);
    },
    [dispatch, snackbar]
  );

  const generateVoucherCode = useCallback(async () => {
    setIsCodeLoading(true);
    const res = unwrapResult(
      await dispatch(voucherActions.generateVoucherCodeThunk())
    );

    if (res?.success && res?.originalData?.data?.code) {
      return res?.originalData?.data?.code;
    } else {
      console.error(
        'Failed to generate code. Try again later or contact devs',
        res?.originalData?.message
      );
      snackbar.show({
        useSound: true,
        severity: 'error',
        message:
          res?.originalData?.message ||
          'Failed to generate code. Try again later or contact devs'
      });
    }
  }, [dispatch, snackbar]);

  const getVouchersCSV = useCallback(async () => {
    const res = unwrapResult(
      await dispatch(
        voucherActions.getVouchersForCSVThunk({ ...appliedFilter, limit: 9999 })
      )
    ).originalData;
    if (res.success) {
      const csvData: Voucher[] = res.data || [];
      return csvData;
    } else {
      console.error('Failed to get voucher list', res?.message);
      snackbar.show({
        useSound: true,
        severity: 'error',
        message:
          res?.message ||
          'Failed to get voucher list. Try again later or contact devs'
      });
      return [];
    }
  }, [appliedFilter, dispatch, snackbar]);

  const getVouchers = useCallback(
    async (payload = { page: 1 }) => {
      const res = unwrapResult(
        await dispatch(
          voucherActions?.getVouchersThunk({
            ...appliedFilter,
            ...payload
          })
        )
      )?.originalData;
      if (!res?.success) {
        console.error('Failed to get voucher list', res?.message);
        snackbar?.show({
          useSound: true,
          severity: 'error',
          message:
            res?.message ||
            'Failed to get voucher list. Try again later or contact devs'
        });
      }
    },
    [appliedFilter, dispatch, snackbar]
  );

  const deleteVoucher = useCallback(
    async (voucher: Voucher) => {
      const { id } = voucher;
      if (!id) {
        console.error('No valid id to delete');
        return;
      }

      const res = unwrapResult(
        await dispatch(voucherActions.deleteVoucherThunk(id))
      );
      if (!res.success) {
        snackbar.show({
          severity: 'error',
          message: 'Failed to delete voucher, try again later or contact devs',
          useSound: true
        });
      } else {
        getVouchers({ page: currentPage });
      }
      setIsDelete(false);
    },
    [currentPage, dispatch, getVouchers, snackbar]
  );

  const onClickDelete = useCallback(() => {
    setIsDelete(true);
  }, []);

  const saveCurrentFilters = useCallback(() => {
    dispatch(
      voucherActions.setFilters({
        code: keyword,
        discount_type: voucherType
      })
    );
  }, [dispatch, keyword, voucherType]);

  const onChangePage = useCallback(
    (e: any, value: number) => {
      getVouchers({ page: value });
    },
    [getVouchers]
  );

  const getVoucherTypeOptions = useCallback(async () => {
    setIsOptionsLoading(true);
    const res = unwrapResult(
      await dispatch(voucherActions?.getVoucherTypeOptionsThunk())
    )?.originalData;
    if (res?.success) {
      setTypeOptions(res?.data?.split(',') || []);
    } else {
      snackbar?.show({
        severity: 'error',
        message: 'Failed to get voucher type options'
      });
    }
    setIsOptionsLoading(false);
  }, [dispatch, snackbar]);

  return {
    getVouchers,
    onChangePage,
    vouchersList,
    currentPage,
    isOptionsLoading,
    lastPage,
    getVoucherTypeOptions,
    typeOptions,
    isListLoading,
    keyword,
    onChangeKeyword,
    deleteVoucher,
    getVouchersCSV,
    generateVoucherCode,
    createVoucher,
    voucherType,
    setVoucherType,
    saveCurrentFilters,
    isCodeLoading,
    isCreateLoading,
    onClickDelete,
    isDelete,
    setIsDelete
  };
};
